import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetState from '../../../../../helpers/GetState'
import GetCity from '../../../../../helpers/GetCity'
import GetLocation from '../../../../../helpers/GetLocation'
import GetSector from '../../../../../helpers/GetSector'
import Select from 'react-select'
import GetAmenity from '../../../../../helpers/GetAmenity'

export default function HomepageDetail() {
    const [homepageDetail, setHomepageDetail] = useState(null)
    const [getStateList, setStateList] = useState(null)
    const urlParams = useParams()
    const [homeBanner, setHomeBanner] = useState(null)
    const [states, setStates] = useState(null)
    const [city, setCity] = useState(null)
    const [location, setLocation] = useState(null)
    const [sector, setSector] = useState(null)
    const [selectedAmenity, setSelectedAmenity] = useState([])
    const [amenities, setAmenities] = useState(null)
    const projectImage = useRef()
    const dataIcon = useRef()
    const dataIcon2 = useRef()
    const getState = useRef()
    const getCity = useRef()
    const getLocation = useRef()
    const GetSectorrrr = useRef()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
                const stateList = await GetState()
                setStates(stateList)
            })();
            (async()=>{
                const amenity = await GetAmenity()
                const amenityArray = []
                for(let i in amenity){
                    amenityArray.push({
                        label: `${amenity[i].name}`,
                        value: amenity[i]._id,
                    })
                }
                setAmenities(amenityArray)
                })();
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const nearByIcon1 = useRef()
    const nearByIcon2 = useRef()
    const nearByIcon3 = useRef()
    let addedAmenity = []
   
    if(selectedAmenity.length < 0){
        toast.error('Amenities are required',{
            toastId: 'iconRequired'
        })
        return false
    }else{
        for(let i = 0; i < selectedAmenity.length; i++){
            addedAmenity.push(selectedAmenity[i].value)
    }
    }
    
    const updateHome = async (values, event) => {
        const data = {
            name: values.name, 
            description: values.description, 
            bannerTitle: values.bannerTitle, 
            metaDescription: values.metaDescription, 
            metaKeyword: values.metaKeyword, 
            metaTitle: values.metaTitle, 
            title: values.title, 
            location: getLocation.current.value, 
            city: getCity.current.value, 
            about: values.about, 
            map: values.map, 
            youtube: values.youtube || "",
            state: getState.current.value, 
            sector: GetSectorrrr.current.value, 
            sortOrder: values.sortOrder,
            active: values.active == 1 ? true : false, 

            // property fields
            nearbyTitle1: values.nearbyTitle1 || "",
            nearbySubTitle1: values.nearbySubTitle1 || "",
            nearbyTitle2: values.nearbyTitle2 || "",
            nearbySubTitle2: values.nearbySubTitle2 || "",
            nearbyTitle3: values.nearbyTitle3 || "",
            nearbySubTitle3: values.nearbySubTitle3 || "",
            drone: values.drone || "",
            amenities: addedAmenity,
            landmark: values.landmark || ""
        }
        const formData = new FormData()
        for(var key in data) {
            if(data[key] !== "") {
                if(key == "amenities"){
                    formData.append(key, JSON.stringify(data[key])) 
                }else{
                    formData.append(key, data[key])
                }
            }
            if(data[key] === "" && key != 'youtube' && key != "drone" &&  key != 'nearbyTitle1' &&  key != 'nearbySubTitle1' &&  key != 'nearbyTitle2' &&  key != 'nearbySubTitle2' &&  key != 'nearbyTitle3' &&  key != 'nearbySubTitle3' && key != 'landmark' && key != "sector") {
                toast.error(`Some Fields are Empty please check (${key})`,{
                    toastId: 'EmptyFields'
                })
                return false
            }
        }

        formData.append("latitude", '""');
        formData.append("longitude", '""');

        if(projectImage.current.value === ''){
            toast.error('Project Photos are required',{
                toastId: 'iconRequired'
            })
            return false
        }

        if(projectImage.current.value !== ''){
            for(let i = 0; i < projectImage.current.files.length; i++){
                formData.append("coverPhoto", projectImage.current.files[i])
            }
        }
        if(nearByIcon1.current.value !== ''){
            for(let i = 0; i < nearByIcon1.current.files.length; i++){
                formData.append("nearbyIcon1", nearByIcon1.current.files[i])
            }
        }
        if(nearByIcon2.current.value !== ''){
            for(let i = 0; i < nearByIcon2.current.files.length; i++){
                formData.append("nearbyIcon2", nearByIcon2.current.files[i])
            }
        }
        if(nearByIcon3.current.value !== ''){
            for(let i = 0; i < nearByIcon3.current.files.length; i++){
                formData.append("nearbyIcon3", nearByIcon3.current.files[i])
            }
        }

        if(dataIcon.current.value === ''){
            toast.error('Banner image is required',{
                toastId: 'iconRequired'
            })
            return false
        }
        if(dataIcon2.current.value === ''){
            toast.error('Thumbnail image is required',{
                toastId: 'iconRequired'
            })
            return false
        }
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }

        if(dataIcon2.current.value != ''){
            formData.append("blog", dataIcon2.current.files[0])
        }
        
        const update = await Ajax({
            url: `/developer`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Developer Add Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
        console.log(update)
    }
        //Seelct State for City
        const chooseState = async(e) => {
            var stateId = e.target.value
            const cityFetch = await GetCity(stateId)
            setCity(cityFetch)
        }
    
        //Select City for Location
        const chooseCity = async(e) => {
            var cityId = e.target.value
            const locationFetch = await GetLocation(cityId)
            setLocation(locationFetch)
        }
    
        //Select Location for sector
        const chooseLocation = async(e) => {
            var locationId = e.target.value
            const sectorFetch = await GetSector(locationId)
            setSector(sectorFetch)
        }

        //Select Amenity
        const handleAmenity = (selectedAmenity) => {
            setSelectedAmenity([...selectedAmenity])
        }
        
        const handleImageSize =(e)=>{
            const files = e.target.files || []
            if(files && files.length > 0){
                for(const file of files){
                if(file.size > 1024*1024) {
                    toast.error('Image size should not exceed 1 MB',{
                        toastId: 'iconRequired'
                    })
                    e.target.value = ''
                    return false
                }
            }
            }
        }
    return (
        <ContentLayout title="Add Developer">
            <Helmet>
                <title>Add Developer</title>
            </Helmet>
            <DataView title="Infomation">
        
                <Formik
                    initialValues={{ 
                        name: '',
                        description: '', 
                        bannerTitle:'',
                        metaDescription:'',
                        metaKeyword:'',
                        metaTitle:'',
                        title:'',
                        location:'',
                        city:'',
                        about:'',
                        map:'',
                        state:'',
                        sector:'',
                        youtube: '',
                        sortOrder: 1,
                        active: 1
                    }}
                    // validationSchema={Yup.object({
                        //name: Yup.string().required('Field is Required'),
                        //description: Yup.string().required('Field is Required'),
                        //bannerTitle: Yup.string().required('Field is Required'),
                        //metaDescription: Yup.string().required('Field is Required'),
                        //metaKeyword: Yup.string().required('Field is Required'),
                        //metaTitle: Yup.string().required('Field is Required'),
                        //title: Yup.string().required('Field is Required'),
                        // location: Yup.string().required('Field is Required'),
                        // city: Yup.string().required('Field is Required'),
                        //about: Yup.string().required('Field is Required'),
                        //map: Yup.string().required('Field is Required'),
                        // state: Yup.string().required('Field is Required'),
                        // sector: Yup.string().required('Field is Required')
                    // })}
                    onSubmit={(values, event) => {
                        updateHome(values, event)
                    }}
                >
                <Form>
                    <ul className="nav nav-tabs">
                    <li className="nav-item"><a href="#tab-general" data-bs-toggle="tab" className="nav-link active">General</a></li>
                    <li className="nav-item"><a href="#tab-banner" data-bs-toggle="tab" className="nav-link ">Banner</a></li>
                    <li className="nav-item"><a href="#tab-developer" data-bs-toggle="tab" className="nav-link ">Developer</a></li>
                    <li className="nav-item"><a href="#tab-near-buy" data-bs-toggle="tab" className="nav-link">Near By</a></li>
                    <li className="nav-item"><a href="#tab-meta" data-bs-toggle="tab" className="nav-link ">Meta Data</a></li>
                    </ul>
                    <div className="tab-content">
                    <div className="tab-pane active" id="tab-general">
                            <div className='row'>
                            <div className='col-md-12'>
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                            </div>
                            </div>
                           
                          
                            <div className='row'>
                                <div className='col-md-12'>
                                    <FormGroup label="Project Description" required>
                                        <Field as="textarea" placeholder='Description' name="about" className='form-control' />
                                        <ErrorField><ErrorMessage name="about"/></ErrorField>
                                    </FormGroup>
                                    <FormGroup label="Sort Order" required>
                                        <Field type="number" placeholder='Sort Order' name="sortOrder" className='form-control' />
                                        <ErrorField><ErrorMessage name="sortOrder"/></ErrorField>
                                    </FormGroup>
                                </div>
                               
                            </div>
                            
                        </div>
                    
                        <div className="tab-pane " id="tab-banner">
                            <FormGroup label="Banner Image" required>
                                <div className='col-md-12'>
                                    <input type="file" ref={dataIcon} name="banner_image" className='form-control' onChange={handleImageSize}/>
                                </div>
                                <div className='col-md-3 mt-3'>
                                </div>
                            </FormGroup>
                            <FormGroup label="Banner Title" required>
                                <Field placeholder='Banner Title' name="bannerTitle" className='form-control' />
                                <ErrorField><ErrorMessage name="bannerTitle"/></ErrorField>
                            </FormGroup>
                          
                        </div>

                        
                        <div className="tab-pane " id="tab-developer">
                            <div className='row'>
                            <div className='col-md-6'>
                            <FormGroup label="Name" required>
                                <Field placeholder='Name' name="name" className='form-control' />
                                <ErrorField><ErrorMessage name="name"/></ErrorField>
                            </FormGroup>
                            </div>
                            <div className='col-md-6'>
                            <FormGroup label="Thumbnail Image" required>
                                    <input type="file" name="file" ref={dataIcon2} className='form-control' onChange={handleImageSize}/>
                            </FormGroup>
                            </div>
                            <div className='col-md-6'>
                                    <FormGroup label="Project Photos" required>
                                        <input type="file" name="projectPhotos" onChange={handleImageSize} ref={projectImage} className='form-control' multiple accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="projectPhotos"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Drone Video (Youtube Id)">
                                        <Field placeholder="id" name="drone" className='form-control' />
                                        <ErrorField><ErrorMessage name="drone"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-12'>
                                <FormGroup label="Project Videos (Youtube Id)" >
                                <Field as="textarea" placeholder="id1,id2" name="youtube" className='form-control' />
                                <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                            </FormGroup>
                                </div>
                            <div className='col-md-3'>
                                    <FormGroup label="State" required>
                                        <select name="state" ref={getState} className='form-select' defaultValue='' onChange={chooseState}>
                                            <option value="" disabled>Select</option>
                                            {states === null ? <option disabled>Loading</option> : states.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="state"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="City" required>
                                        <select name="state" ref={getCity} defaultValue='' className='form-select' onChange={chooseCity}>
                                            <option value="" disabled>Select</option>
                                            {city === null ? <option disabled>Select State</option> : city.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="state"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="Location" required>
                                        <select name="locationId" ref={getLocation} defaultValue='' className='form-select' onChange={chooseLocation}>
                                            <option value="" disabled>Select</option>
                                            {location == null ? <option disabled>Loading</option> : location.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="locationId"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="Sector" >
                                        <select name="sector" ref={GetSectorrrr} defaultValue='' className='form-select'>
                                            <option value="">None</option>
                                            {sector == null ? <option disabled>Loading</option> : sector.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="sector"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Landmark">
                                        <Field name="landmark" placeholder="Landmark" className='form-control'/>
                                        <ErrorField><ErrorMessage name="landmark"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Select Amenity" required>
                                        <div className='col-md-12'>
                                            <Select 
                                                options={amenities} 
                                                onChange={handleAmenity}
                                                closeMenuOnSelect={false}
                                                isMulti
                                             />
                                        </div>
                                    </FormGroup>
                                </div>
                                {/* <FormGroup label="Youtube Id">
                                    <Field placeholder='Youtube Id' name="youtube" className='form-control' />
                                    <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                                </FormGroup> */}
                                <div className='col-md-12'>
                                <FormGroup label="Google Map Iframe" required>
                                    <Field as="textarea" placeholder='Google Map Iframe' name="map" className='form-control' />
                                    <ErrorField><ErrorMessage name="map"/></ErrorField>
                                </FormGroup>
                                </div>
                                <div className='col-md-12'>
                                <FormGroup label="Open For Sell Flat In Project" required>
                                    <Field as="textarea" placeholder='Open For Sell Flat In Project' name="description" className='form-control' />
                                    <ErrorField><ErrorMessage name="description"/></ErrorField>
                                </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-near-buy">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title" >
                                        <Field name="nearbyTitle1" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle1"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title" >
                                        <Field name="nearbySubTitle1" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle1"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <FormGroup label="Icon" >
                                        <input type="file" ref={nearByIcon1} onChange={handleImageSize} name="nearbyIcon1" className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="nearbyIcon1"/></ErrorField>
                                    </FormGroup>
                                    {/* {productDetail.nearbyIcon1 && 
                                    <img src={productDetail.nearbyIcon1.path} width={80} /> } */}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title" >
                                        <Field name="nearbyTitle2" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle2"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title" >
                                        <Field name="nearbySubTitle2" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle2"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <FormGroup label="Icon" >
                                        <input type="file" name="nearbyIcon2" onChange={handleImageSize} ref={nearByIcon2} className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="nearbyIcon2"/></ErrorField>
                                    </FormGroup>
                                    {/* {productDetail.nearbyIcon1 && 
                                    <img src={productDetail.nearbyIcon1.path} width={80} /> } */}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title" >
                                        <Field name="nearbyTitle3" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle3"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title" >
                                        <Field name="nearbySubTitle3" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle3"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <FormGroup label="Icon" >
                                        <input type="file" name="nearbyIcon3" onChange={handleImageSize} ref={nearByIcon3} className='form-control' accept='.jpg, .jpeg, .png, .webp'/>
                                        <ErrorField><ErrorMessage name="nearbyIcon3"/></ErrorField>
                                    </FormGroup>
                                    {/* {productDetail.nearbyIcon1 && 
                                    <img src={productDetail.nearbyIcon1.path} width={80} /> } */}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane " id="tab-meta">
                            <div className='row'>
                                <div className='col-md-12'>
                                <FormGroup label="Meta Title" required>
                                <Field placeholder='Meta Title' name="metaTitle" className='form-control' />
                                <ErrorField><ErrorMessage name="metaTitle"/></ErrorField>
                            </FormGroup>
                            </div>

                            <div className='col-md-12'>
                                <FormGroup label="Meta Keyword" required>
                                <Field placeholder='Meta Keyword' name="metaKeyword" className='form-control' />
                                <ErrorField><ErrorMessage name="metaKeyword"/></ErrorField>
                            </FormGroup>
                            </div>

                            <div className='col-md-12'>
                                <FormGroup label="Meta Description" required>
                                <Field as="textarea" placeholder='Meta Description' name="metaDescription" className='form-control' />
                                <ErrorField><ErrorMessage name="metaDescription"/></ErrorField>
                            </FormGroup>
                            </div>

                            </div>
                        </div>
                        
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik> 
            </DataView>
        </ContentLayout>
    )
}