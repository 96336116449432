import React, { useState, useEffect, useRef } from "react";
import DataView from "../../../../UI/Dashboard/DataView";
import Ajax from "../../../../../helpers/Ajax";
import { useParams, useNavigate } from "react-router-dom";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import TableContainer from "../../../../UI/Dashboard/TableContainer";
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import Button from "../../../../UI/Form/Button";
import ContentLayout from "../../../../Common/ContentLayout";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import CustLoader from "../../../../Common/CustLoader";
import GetState from "../../../../../helpers/GetState";
import GetCity from "../../../../../helpers/GetCity";
import axios from "axios";
import Notification from '../../../../Common/notification'

export default function Updatebroker() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const userValidToken = localStorage.getItem("userToken");
  const dataIcon = useRef();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState(null);
  const [city, setCity] = useState(null);
  const [cityStates, setCityStates] = useState([]);
  const [validDateShow,setValidDateShow] = useState()
  const [ValidTillDate,setValidTillDate] = useState()
  const [BrokerDetails, setBrokerDetail] = useState(null);
  const [relationManager,setRelationManager] = useState([])
  const [brokerMembers,setBrokerMembers] = useState([])
  const [selectedRm,setSelectedRm] = useState()
  const [isDisabled, setIsDisabled] = useState(false)
  const [uploadStatus, setUploadStatus] = useState({});
  const [aops, setAops] = useState(null);
  const [kycDoc, setKycDoc] = useState("") 
  const [uploadDocuments, setUploadDocuments] = useState({
    panCardDocUrl: "",
    gstDocUrl: "",
    cancelledChequeDocUrl: "",
    adharCardDocUrl: "",
    adharCardBackDocUrl: "",
    signature:""
  });

  const linkBrokerAop = async (data) => {
    const update = await JourneyAjax({
      url: `/brokeraops/admin`,
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "x-user": userValidToken || null,
      method: "PATCH",
      data: data,
      token: true,
      loader:true
    });
    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }
    if (update.status === 200) {
      toast.success("Aop Linked Successfully");
    }
  };

  const chooseCity = (e) => {
    setSelectedCity(e.target.value);
  };
  const chooseState = (e) => {
    setSelectedState(e.target.value);
    setSelectedCity("");
    for (const [key, value] of Object.entries(cityStates)) {
      if (key === e.target.value) {
        setCity(value);
      }
    }
  };

  useEffect(() => {
    console.log("selectedCity", selectedCity);
  }, [selectedCity]);

  useEffect(() => {
    if (selectedState) {
      for (const [key, value] of Object.entries(cityStates)) {
        if (key === selectedState) {
          setCity(value);
        }
      }
    }
  }, [selectedState]);

  useEffect(() => {
    const getStates = setTimeout(function() {
      (async () => {
        let result = await JourneyAjax({
          url: `/appConstants`,
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          token: true,
        });
        setCityStates(result.data.data.cityState);
        setStates(Object.keys(result.data.data.cityState));
      })();
    });
    const getAops = setTimeout(function() {
      (async () => {
        const response = await JourneyAjax({
          url: `/aops`,
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "x-user": userValidToken || null,
          token: true,
        });

        if (response && response.status === 200) {
          setAops(response.data.data.aops);
        }
        const rmDetails= await JourneyAjax({
          url: `/relation-managers?filter={"offset": 0,"limit": 10,"order":["createdAt DESC"]}`,
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "x-user": userValidToken || null,
          token: true,
          });
          if(rmDetails && rmDetails.data && rmDetails.data.data && rmDetails.data.data.relationManagers && rmDetails.data.data.relationManagers.length > 0){
            setRelationManager(rmDetails.data.data.relationManagers)
          }
      })();
    }, 100);

    const getResponse = setTimeout(function() {
      (async () => {
        const response = await JourneyAjax({
          url: `/admin/brokers/${urlParams.BrokerId}`,
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "x-user": userValidToken || null,
          token: true,
        });
        if (response.status === 200) {
          setBrokerDetail(response.data.data.brokerData);
          setSelectedState(response.data.data.brokerData?.state || "");
          setSelectedCity(response.data.data.brokerData?.city || "");
          setBrokerMembers(response.data.data.brokerMemberData || []);
          setKycDoc(response.data.data.brokerData?.kycDoc || "")
          let documents = {};
          const broker = response.data.data.brokerData;
          if(broker && broker.linkedRm){
            setSelectedRm(broker.linkedRm)
          }
          if(broker && broker.brokeraop){
              let validDate = new Date()
              let formattedDate = new Date()
            if(broker.brokeraop.validTill){
                 validDate = new Date(broker.brokeraop.validTill);
                 formattedDate = validDate.toISOString().split('T')[0];
            }else{
              const currentYear = new Date().getFullYear();
              // Get the last date of the current year
               validDate = new Date(currentYear,12,0,23,59,59); // Month is zero-based index, so 11 is December
               formattedDate = validDate.toISOString().split('T')[0];
            }
              setValidDateShow(formattedDate)
              setValidTillDate(validDate)
          }
          if (broker && broker.documents && broker.documents.panCard) {
            documents.panCardDocUrl = Array.isArray(broker.documents.panCard) ? broker.documents.panCard[0] : broker.documents.panCard;
          }
          if (broker && broker.documents && broker.documents.adharCard) {
            documents.adharCardDocUrl = Array.isArray(broker.documents.adharCard) ? broker.documents.adharCard[0] : broker.documents.adharCard;
          }
          if (broker && broker.documents && broker.documents.gst) {
            documents.gstDocUrl = Array.isArray(broker.documents.gst) ? broker.documents.gst[0] : broker.documents.gst;
          }
          if (broker && broker.documents && broker.documents.cancelledCheque) {
            documents.cancelledChequeDocUrl = Array.isArray(broker.documents.cancelledCheque) ? broker.documents.cancelledCheque[0] : broker.documents.cancelledCheque;
          }
          if (broker && broker.documents && broker.documents.signature) {
            documents.signature = Array.isArray(broker.documents.signature) ? broker.documents.signature[0] : broker.documents.signature;
          }
          if (broker && broker.documents && broker.documents.mouLink) {
            documents.mouLink = Array.isArray(broker.documents.mouLink) ? broker.documents.mouLinkp[0] : broker.documents.mouLink;
          }
          if (broker && broker.documents && broker.documents.adharCardBack) {
            documents.adharCardBackDocUrl = Array.isArray(broker.documents.adharCardBack) ? broker.documents.adharCardBack[0] : broker.documents.adharCardBack;
          }

          setUploadDocuments(documents);
        }
      })();
    }, 100);

    return () => {
      clearInterval(getResponse);
    };
  }, []);

  const handleDocUpload = async (e, docType) => {
    setIsDisabled(true)
    const file = e.target.files[0];
    if (!file) return;

    setUploadStatus((prevStatus) => ({
      ...prevStatus,
      [docType]: "Pending",
    }));

    const formData = new FormData();
    formData.append("file", file);
    // const URL = `https://dev-journey-api.houseeazy.com/images`;
    const URL = `${process.env.REACT_APP_JOURNEY_URL}/images`;
    const reqHeaders = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(URL, formData, reqHeaders);
      const uploadDocRes = response.data[0];

      // Update the appropriate state based on imageType
      if (docType === "panCard") {
        setUploadDocuments((prev) => ({
          ...prev,
          panCardDocUrl: uploadDocRes,
        }));
      } else if (docType === "gst") {
        setUploadDocuments((prev) => ({
          ...prev,
          gstDocUrl: uploadDocRes,
        }));
      } else if (docType === "cancelledCheque") {
        setUploadDocuments((prev) => ({
          ...prev,
          cancelledChequeDocUrl: uploadDocRes,
        }));
      } else if (docType === "adharCard") {
        setUploadDocuments((prev) => ({
          ...prev,
          adharCardDocUrl: uploadDocRes,
        }));
      }else if (docType === "adharCardBack") {
        setUploadDocuments((prev) => ({
          ...prev,
          adharCardBackDocUrl: uploadDocRes,
        }));
      }else if (docType === "kycDoc") {
        setKycDoc(uploadDocRes)
      }
      // Set upload status to done
      setUploadStatus((prevStatus) => ({
        ...prevStatus,
        [docType]: "Done",
      }));
      e.target.value=null
      setIsDisabled(false)
    } catch (error) {
      console.error("Error uploading image:", error);
      setIsDisabled(false)
    }
  };


  const deleteBrokerImages = async (document) =>{
    let confirm = window.confirm("Are you sure want to remove this document ?");
    if(confirm){
    if (document === "panCard") {
      setUploadDocuments((prev) => ({
        ...prev,
        panCardDocUrl: "",
      }));
    } else if (document === "gst") {
      setUploadDocuments((prev) => ({
        ...prev,
        gstDocUrl: "",
      }));
    } else if (document === "cancelledCheque") {
      setUploadDocuments((prev) => ({
        ...prev,
        cancelledChequeDocUrl: "",
      }));
    } else if (document === "adharCard") {
      setUploadDocuments((prev) => ({
        ...prev,
        adharCardDocUrl: "",
      }));
    }else if (document === "adharCardBack") {
      setUploadDocuments((prev) => ({
        ...prev,
        adharCardBackDocUrl: "",
      }));
    }else if (document === "kycDoc") {
      setKycDoc("")
    }
  }else{
    return false
  }
  }

  const updateBroker = async (values, event) => {
    const documents = {};
    const {
      panCardDocUrl,
      adharCardDocUrl,
      cancelledChequeDocUrl,
      gstDocUrl,
      adharCardBackDocUrl
    } = uploadDocuments;
     documents.panCard = panCardDocUrl ?? "";
     documents.adharCard = adharCardDocUrl ?? "";
     documents.adharCardBack = adharCardBackDocUrl ?? "";
     documents.cancelledCheque = cancelledChequeDocUrl ?? "";
     documents.gst = gstDocUrl ?? "";

    const data = {
      name: values.name,
      email: values.email ?? "",
      mobile: values.mobile,
      firmName: values.firmName ?? "",
      firmType: values.firmType,
      approved:
        values.approved === "true" || values.approved === true ? true : false,
      // otpVerified: values.otpVerified === "true" ? true : false,
      aopSigned:
        values.aopSigned === "true" || values.aopSigned === true ? true : false,
      documents: documents,   
      kycDoc : kycDoc ?? "",
      linkedRm:selectedRm || ""
      //   shortlistedPropertyId: [values.shortlistedPropertyId],
    };

    let stateObj, cityObj;

    if (selectedState && selectedState.length > 0) {
      data.state = selectedState;
    }

    if (selectedCity && selectedCity.length > 0) {
      data.city = selectedCity;
    }

    const update = await JourneyAjax({
      url: `/brokers/admin/${urlParams.BrokerId}`,
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "x-user": userValidToken || null,
      method: "PATCH",
      data: data,
      token: true,
      loader: true
    });
    if (update.data.error) {
      toast.error(update.data.error.message);
    }
    if (update.status === 200) {
      if (update.data.data.aopSigned) {
        const aopData = {
          aopId: values.aopId
            ? values.aopId
            : BrokerDetails &&
              BrokerDetails.brokeraop &&
              BrokerDetails.brokeraop.aopId,
          brokerId: update.data.id,
          value: values.value
            ? values.value
            : BrokerDetails &&
              BrokerDetails.brokeraop &&
              BrokerDetails.brokeraop.value,
          name: values.brokerAopName
            ? values.brokerAopName
            : BrokerDetails &&
              BrokerDetails.brokeraop &&
              BrokerDetails.brokeraop.name,
          status: values.status
            ? values.status
            : BrokerDetails &&
              BrokerDetails.brokeraop &&
              BrokerDetails.brokeraop.status,
          validTill: ValidTillDate
            ? ValidTillDate
            : BrokerDetails &&
              BrokerDetails.brokeraop &&
              BrokerDetails.brokeraop.validTill,
          brokerId: BrokerDetails && BrokerDetails.id,
          currentValue: values.currentValue
            ? values.currentValue
            : BrokerDetails &&
              BrokerDetails.brokeraop &&
              BrokerDetails.brokeraop.currentValue,
        };
        linkBrokerAop(aopData);
      }
      toast.success("Broker Updated Successfully");
      setTimeout(function() {
        navigate(`/admin/property-journey/broker`);
      }, 500);
    }
  };

  function isPDFUrl(url) {
    if (typeof url === "string") {
      return url && url.toLowerCase().endsWith(".pdf");
    }
  }
  const handleRelationManager = (e) =>{
    setSelectedRm(e.target.value)
   }
   const handleValidTillDate = (selectedAop) =>{
    let validDate = new Date()
    let formattedDate = new Date()
    if(selectedAop && selectedAop.validTill){
       validDate = new Date(selectedAop.validTill); 
       formattedDate = validDate.toISOString().split('T')[0];
    }else{
    const currentYear = new Date().getFullYear();
    // Get the last date of the current year
     validDate = new Date(currentYear,12,0,23,59,59); // Month is zero-based index, so 11 is December
     formattedDate = validDate.toISOString().split('T')[0];
  }
    setValidDateShow(formattedDate)
    setValidTillDate(validDate)
  }
  const deleteMember = async (id) => {
    try{
      let confirm = window.confirm("Are you sure want to remove this member ?");
      if(confirm){
      await JourneyAjax({
        url: `/admin/broker/member/${id}`,
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        "x-user": userValidToken,
        method:"DELETE",
        token: true,
        loader:true
      });
        toast.success('Member remove successfully',{
          autoClose: 1000
        })
        setTimeout(function(){
          window.location.reload()
        },500)
      }else{
        return false
      }
    }catch(error){
     console.log(error)
     toast.error('something went wrong',{
      autoClose: 1000
    })
    }
    
  }
  return (
    <ContentLayout title="Edit New Broker" notification={<Notification url={`/brokers/pushNotification?filter={"where":{"id":"${urlParams.BrokerId}"}}`}/>}>
      <Helmet>
        <title>Edit Broker</title>
      </Helmet>
        {/* <Notification url={`/brokers/pushNotification?filter={"where":{"id":"${urlParams.BrokerId}"}}`}/>      */}
      <DataView title="Infomation">
        {BrokerDetails === null ? (
          <DataLoader />
        ) : (
          <Formik
            initialValues={{
              name: BrokerDetails && BrokerDetails.name,
              email: BrokerDetails && BrokerDetails.email,
              mobile: BrokerDetails && BrokerDetails.mobile,
              firmName: BrokerDetails && BrokerDetails.firmName,
              firmType: BrokerDetails && BrokerDetails.firmType,
              city: selectedCity || "",
              // state: selectedState || "",
              approved:
                (BrokerDetails && BrokerDetails.approved === true) ||
                (BrokerDetails && BrokerDetails.approved === "true")
                  ? true
                  : false,
              // otpVerified: BrokerDetails && BrokerDetails.otpVerified,
              aopSigned:
                (BrokerDetails && BrokerDetails.aopSigned === "true") ||
                (BrokerDetails && BrokerDetails.aopSigned === true)
                  ? true
                  : false,
              aopId:
                BrokerDetails &&
                BrokerDetails.brokeraop &&
                BrokerDetails.brokeraop.aopId,
              value:
                BrokerDetails &&
                BrokerDetails.brokeraop &&
                BrokerDetails.brokeraop.value,
              currentValue:
                BrokerDetails &&
                BrokerDetails.brokeraop &&
                BrokerDetails.brokeraop.currentValue,
              brokerAopName:
                BrokerDetails &&
                BrokerDetails.brokeraop &&
                BrokerDetails.brokeraop.name,
              status:
                BrokerDetails &&
                BrokerDetails.brokeraop &&
                BrokerDetails.brokeraop.status,
              validTill:
                BrokerDetails &&
                BrokerDetails.brokeraop &&
                BrokerDetails.brokeraop.validTill &&BrokerDetails.brokeraop.validTill,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Broker Name is required"),
              email: Yup.string()
                .email("Invalid email").nullable(),
              mobile: Yup.string().required("Mobile Number is required"),
              firmName: Yup.string().nullable(),
              firmType: Yup.string().required("Firm Type is required"),
              approved: Yup.string().required("Approved is required"),
              aopSigned: Yup.boolean().required("AOP Signed is required"),
              panCard: Yup.mixed().nullable(),
              adharCard: Yup.mixed().nullable(),
              adharCardBack: Yup.mixed().nullable(),
              kycDoc: Yup.mixed().nullable(),
              gst: Yup.mixed().nullable(),
              cancelledCheque: Yup.mixed().nullable(),
              aopId: Yup.string().when("aopSigned", {
                is: true,
                then: Yup.string().required(
                  "AOP ID is required when AOP Signed is true"
                ),
                otherwise: Yup.string().nullable(),
              }),
              state: Yup.string().nullable(),
              city: Yup.string().nullable(),
              validTill: Yup.date().nullable().when('aopId', {
                is: aopId => !!aopId, // Only when aopId is selected
                // then: Yup.date().required('Valid Till is required').typeError('Valid Till must be a date'),
                otherwise: Yup.date().nullable(),
              }),
            })}
            onSubmit={(values, event) => {
              updateBroker(values, event);
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <ul className="nav nav-tabs">
                        <li className="nav-item"><a href="#tab-broker" data-bs-toggle="tab" className="nav-link active">Broker Info</a></li>
                        <li className="nav-item"><a href="#tab-members" data-bs-toggle="tab" className="nav-link">Members</a></li>
                </ul>
                <div className="tab-content">
                <div className="tab-pane active" id="tab-broker">
                <div className="row">
                
                  <div className="col-md-6">
                    <FormGroup label="Broker Name" required>
                      <Field
                        placeholder="Broker Name"
                        name="name"
                        className="form-control"
                      />
                      <ErrorField>
                        <ErrorMessage name="name" />
                      </ErrorField>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup label="Email">
                      <Field
                        placeholder="Email"
                        type="email"
                        name="email"
                        className="form-control"
                      />
                      <ErrorField>
                        <ErrorMessage name="email" />
                      </ErrorField>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup label="Mobile" required>
                      <Field
                        placeholder="Mobile Number"
                        type="string"
                        name="mobile"
                        className="form-control"
                      />
                      <ErrorField>
                        <ErrorMessage name="mobile" />
                      </ErrorField>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup label="Firm Name">
                      <Field
                        placeholder="Firm Name"
                        name="firmName"
                        className="form-control"
                      />
                      <ErrorField>
                        <ErrorMessage name="firmName" />
                      </ErrorField>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup label="Firm Type" required>
                      <Field
                        as="select"
                        placeholder="Firm Type"
                        name="firmType"
                        className="form-select"
                      >
                        <option value={""}>Select</option>
                        <option value={"individual"}>Individual</option>
                        <option value={"Registered Firm"}>Registered Firm</option>
                        {/* <option value={"proprietorship"}>Proprietorship</option>
                        <option value={"partnership"}>Partnership</option>
                        <option value={"company"}>Company</option>
                        <option value={"limitedLiabilityPartners"}>
                          Limited Liability Partners
                        </option> */}
                      </Field>
                      <ErrorField>
                        <ErrorMessage name="firmType" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Approved" required>
                      <Field
                        as="select"
                        name="approved"
                        className="form-select"
                      >
                        <option value={false}>False</option>
                        <option value={true}>True</option>
                      </Field>
                      <ErrorField>
                        <ErrorMessage name="approved" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                  <FormGroup label="Relation Manager">
                  <select name="relationManager"  className='form-select' value={selectedRm} onChange={handleRelationManager}>
                            <option value="" >Select</option>
                            {relationManager === null ? <option >Loading</option> : relationManager?.map((data)=>(
                            <option value={data.id}>{`${data.name || ""} - ${data.mobile || ""}`}</option>
                           ))}
                    </select>
                    <ErrorField>
                      <ErrorMessage name="relationManager" />
                    </ErrorField>
                  </FormGroup>
                </div>
                  {/* <div className="col-md-6">
                <FormGroup label="Rera Number" >
                  <Field
                    placeholder="Rera Number"
                    name="reraNumber"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="reraNumber" />
                  </ErrorField>
                </FormGroup>
              </div> */}

                 

                  <div className="col-md-6">
                    <FormGroup label="AOP Signed">
                      <Field
                        as="select"
                        name="aopSigned"
                        className="form-select"
                        onChange={(e)=>{setFieldValue("aopSigned",e.target.value); setFieldValue("aopId","");}}
                      >
                        <option value={false}>False</option>
                        <option value={true}>True</option>
                      </Field>
                      <ErrorField>
                        <ErrorMessage name="aopSigned" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  {((values.aopSigned === "true") || (values.aopSigned === true) ) ? (
                    <div className="col-md-6">
                      <FormGroup label="AOP">
                        <Field
                          as="select"
                          name="aopId"
                          onChange={(e) => {
                            
                            setFieldValue("aopId", e.target.value);
                            let selectedAop = aops.find(
                              (item) => item.id === e.target.value
                            );
                            if (selectedAop && selectedAop.name)
                              setFieldValue("brokerAopName", selectedAop.name);
                            if (selectedAop && selectedAop.value)
                              setFieldValue("value", selectedAop.value);
                            if (selectedAop && selectedAop.status)
                              setFieldValue("status", selectedAop.status);
                            if (selectedAop && selectedAop.currentValue)
                              setFieldValue(
                                "currentValue",
                                selectedAop.currentValue
                              );
                            handleValidTillDate(selectedAop)
                          }}
                          className="form-select"
                        >
                          <option value="">Select</option>
                          {aops?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Field>

                        <ErrorField>
                          <ErrorMessage name="aopId" />
                        </ErrorField>
                      </FormGroup>
                    </div>
                  ) : (
                    ""
                  )}

                  {values.aopId && ((values.aopSigned === "true") || (values.aopSigned === true) ) ? (
                    <div className="col-md-12 mt-3">
                      <h3>Broker Aop Details</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup label="Name">
                            <Field
                              placeholder="brokerAopName"
                              name="brokerAopName"
                              className="form-control"
                            />
                            <ErrorField>
                              <ErrorMessage name="brokerAopName" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup label="Value">
                            <Field
                              type="number"
                              placeholder="value"
                              name="value"
                              className="form-control"
                            />
                            <ErrorField>
                              <ErrorMessage name="value" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup label="Current Value">
                            <Field
                              type="number"
                              placeholder="Current Value"
                              name="currentValue"
                              className="form-control"
                            />
                            <ErrorField>
                              <ErrorMessage name="currentValue" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup label="Status">
                            <Field
                              as="select"
                              name="status"
                              className="form-select"
                            >
                              <option value={"active"}>Active</option>
                              <option value={"inactive"}>InActive</option>
                            </Field>
                            <ErrorField>
                              <ErrorMessage name="status" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup label="Valid Till" required>
                            <Field
                              type="date"
                              placeholder="Valid Till"
                              name="validTill"
                              className="form-control"
                              value={validDateShow}
                              onChange={(e)=>{
                              const lastDateOfYear = new Date(e.target.value);
                              const formattedDate = lastDateOfYear.toISOString().split('T')[0];
                              setValidTillDate(lastDateOfYear)
                              setValidDateShow(formattedDate)
                            }}
                            />
                            <ErrorField>
                              <ErrorMessage name="validTill" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                  <FormGroup label="Kyc Doc">
                    <div className="custom-file-upload-wrapper">
                      <span>Choose File</span>
                      <label htmlFor="kycDoc" className="custom-file-upload">
                        {kycDoc
                          ? kycDoc
                          : "No File Chosen"}
                      </label>
                      <div className="custom-file-preview image-thumbs">
                      {uploadStatus.kycDoc === "Pending" && (
                              <CustLoader />
                      )}
                        {kycDoc && (
                          <div className="single-thumb">
                          <button type="button" onClick={()=>deleteBrokerImages("kycDoc")}  className="btn btn-danger" style={{width:"30px",height:"30px"}}>
                              <i className='fas fa-trash' style={{"font-size":"10px"}}></i>
                          </button>
                          <a
                            href={
                              kycDoc
                                ? kycDoc
                                : ""
                            }
                            target="_blank"
                          >

                            {!isPDFUrl(
                              kycDoc
                                ? kycDoc
                                : ""
                            ) ? (
                              <img
                                src={
                                  kycDoc
                                    ? kycDoc
                                    : ""
                                }
                                width={200}
                              />
                            ) : (
                              <img
                              // src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                src="https://cdn.pixabay.com/photo/2018/05/08/18/46/pdf-3383632_1280.png"
                                width={200}
                              />
                            )}
                          </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <input
                      id="kycDoc"
                      type="file"
                      name="kycDoc"
                      onChange={(e) => handleDocUpload(e, "kycDoc")}
                      style={{ display: "none" }}
                      accept=".jpg, .jpeg, .png, .webp, application/pdf"
                    />
                    <ErrorField>
                      <ErrorMessage name="kycDoc" />
                    </ErrorField>
                  </FormGroup>
                </div>
                  <div>
                  <FormGroup label={"Signature"}>
                     <a href={uploadDocuments.signature ? uploadDocuments.signature : ""} target="_blank">
                       {!isPDFUrl(
                                uploadDocuments.signature
                                  ? uploadDocuments.signature
                                  : ""
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.signature
                                      ? uploadDocuments.signature
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                src="https://cdn.pixabay.com/photo/2018/05/08/18/46/pdf-3383632_1280.png"
                                width={80}
                              />
                              )}
                              </a>
                    </FormGroup>
                    {uploadDocuments && uploadDocuments.signature && uploadDocuments.mouLink &&
                    <FormGroup label={"Mou Link"}>
                       <a href={uploadDocuments.mouLink ? uploadDocuments.mouLink : ""} target="_blank">
                       {!isPDFUrl(
                                uploadDocuments.mouLink
                                  ? uploadDocuments.mouLink
                                  : ""
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.mouLink
                                      ? uploadDocuments.mouLink
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                src="https://cdn.pixabay.com/photo/2018/05/08/18/46/pdf-3383632_1280.png"
                                width={80}
                              />
                              )}
                              </a>
                    </FormGroup>
                     }
                    <FormGroup label="PAN">
                      <div className="custom-file-upload-wrapper">
                        <span>Choose File</span>
                        <label htmlFor="panCard" className="custom-file-upload">
                          {uploadDocuments.panCardDocUrl
                            ? uploadDocuments.panCardDocUrl
                            : "No File Chosen"}
                        </label>
                        <div className="custom-file-preview image-thumbs">
                        {uploadStatus.panCard === "Pending" && (
                                <CustLoader />
                              )}
                          {uploadDocuments && (
                            <div className="single-thumb">

                            {uploadDocuments && uploadDocuments.panCardDocUrl && 
                            <button type="button" onClick={()=>deleteBrokerImages("panCard")}  className="btn btn-danger" style={{width:"30px",height:"30px"}}>
                                <i className="fas fa-trash" style={{"font-size":"10px"}}></i>
                            </button>
                            }
                            <a
                              href={
                                uploadDocuments.panCardDocUrl
                                  ? uploadDocuments.panCardDocUrl
                                  : ""
                              }
                              target="_blank"
                            >
                              
                              {!isPDFUrl(
                                uploadDocuments.panCardDocUrl
                                  ? uploadDocuments.panCardDocUrl
                                  : ""
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.panCardDocUrl
                                      ? uploadDocuments.panCardDocUrl
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                src="https://cdn.pixabay.com/photo/2018/05/08/18/46/pdf-3383632_1280.png"
                                width={200}
                                />
                              )}
                            </a>
                            </div>
                          )}
                        </div>
                      </div>

                      <input
                        id="panCard"
                        type="file"
                        name="panCard"
                        onChange={(e) => handleDocUpload(e, "panCard")}
                        style={{ display: "none" }}
                        accept=".jpg, .jpeg, .png, .webp, application/pdf"
                      />
                      <ErrorField>
                        <ErrorMessage name="token_money_invoice" />
                      </ErrorField>
                    </FormGroup>
                  </div>

                  <div>
                    <FormGroup label="Adhaar">
                      <div className="custom-file-upload-wrapper">
                        <span>Choose File</span>
                        <label
                          htmlFor="adharCard"
                          className="custom-file-upload"
                        >
                          {uploadDocuments.adharCardDocUrl
                            ? uploadDocuments.adharCardDocUrl
                            : "No File Chosen"}
                        </label>
                        <div className="custom-file-preview image-thumbs">
                        {uploadStatus.adharCard === "Pending" && (
                                <CustLoader />
                              )}
                          {uploadDocuments && (
                            <div className="single-thumb">
                          {uploadDocuments && uploadDocuments.adharCardDocUrl && 
                            <button type="button" onClick={()=>deleteBrokerImages("adharCard")}  className="btn btn-danger" style={{width:"30px",height:"30px"}}>
                                <i className="fas fa-trash" style={{"font-size":"10px"}}></i>
                            </button>
                          } 
                            <a
                              href={
                                uploadDocuments.adharCardDocUrl
                                  ? uploadDocuments.adharCardDocUrl
                                  : ""
                              }
                              target="_blank"
                            >
                              {!isPDFUrl(
                                uploadDocuments.adharCardDocUrl
                                  ? uploadDocuments.adharCardDocUrl
                                  : ""
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.adharCardDocUrl
                                      ? uploadDocuments.adharCardDocUrl
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                src="https://cdn.pixabay.com/photo/2018/05/08/18/46/pdf-3383632_1280.png"
                                width={200}
                                />
                              )}
                            </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <input
                        id="adharCard"
                        type="file"
                        name="adharCard"
                        onChange={(e) => handleDocUpload(e, "adharCard")}
                        style={{ display: "none" }}
                        accept=".jpg, .jpeg, .png, .webp, application/pdf"
                      />
                      <ErrorField>
                        <ErrorMessage name="adharCard" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  <div>
                  <FormGroup label="Adhaar card back">
                      <div className="custom-file-upload-wrapper">
                        <span>Choose File</span>
                        <label
                          htmlFor="adharCardBack"
                          className="custom-file-upload"
                        >
                          {uploadDocuments.adharCardBackDocUrl
                            ? uploadDocuments.adharCardBackDocUrl
                            : "No File Chosen"}
                        </label>
                        <div className="custom-file-preview image-thumbs">
                        {uploadStatus.adharCardBack === "Pending" && (
                                <CustLoader />
                              )}
                          {uploadDocuments && (
                            <div className="single-thumb">
                          {uploadDocuments && uploadDocuments.adharCardBackDocUrl &&
                            <button type="button" onClick={()=>deleteBrokerImages("adharCardBack")}  className="btn btn-danger" style={{width:"30px",height:"30px"}}>
                                <i className="fas fa-trash" style={{"font-size":"10px"}}></i>
                            </button>
                          }
                            <a
                              href={
                                uploadDocuments.adharCardBackDocUrl
                                  ? uploadDocuments.adharCardBackDocUrl
                                  : ""
                              }
                              target="_blank"
                            >

                              {!isPDFUrl(
                                uploadDocuments.adharCardBackDocUrl
                                  ? uploadDocuments.adharCardBackDocUrl
                                  : ""
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.adharCardBackDocUrl
                                      ? uploadDocuments.adharCardBackDocUrl
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn.pixabay.com/photo/2018/05/08/18/46/pdf-3383632_1280.png"
                                  width={200}
                                />
                              )}
                            </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <input
                        id="adharCardBack"
                        type="file"
                        name="adharCardBack"
                        onChange={(e) => handleDocUpload(e, "adharCardBack")}
                        style={{ display: "none" }}
                        accept=".jpg, .jpeg, .png, .webp, application/pdf"
                      />
                      <ErrorField>
                        <ErrorMessage name="adharCardBack" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup label="GST">
                      <div className="custom-file-upload-wrapper">
                        <span>Choose File</span>
                        <label htmlFor="gst" className="custom-file-upload">
                          {uploadDocuments.gstDocUrl
                            ? uploadDocuments.gstDocUrl
                            : "No File Chosen"}
                        </label>
                        <div className="custom-file-preview image-thumbs">
                          
                        {uploadStatus.gst === "Pending" && <CustLoader />}

                          {uploadDocuments && (
                            <div className="single-thumb">
                            {uploadDocuments && uploadDocuments.gstDocUrl &&
                            <button type="button" onClick={()=>deleteBrokerImages("gst")}  className="btn btn-danger" style={{width:"30px",height:"30px"}}>
                                <i className="fas fa-trash" style={{"font-size":"10px"}}></i>
                            </button>
                            }
                            <a
                              href={
                                uploadDocuments.gstDocUrl
                                  ? uploadDocuments.gstDocUrl
                                  : ""
                              }
                              target="_blank"
                            >
                              {!isPDFUrl(
                                uploadDocuments.gstDocUrl
                                  ? uploadDocuments.gstDocUrl
                                  : ""
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.gstDocUrl
                                      ? uploadDocuments.gstDocUrl
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn.pixabay.com/photo/2018/05/08/18/46/pdf-3383632_1280.png"
                                  width={200}
                                />
                              )}
                            </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <input
                        id="gst"
                        type="file"
                        name="gst"
                        onChange={(e) => handleDocUpload(e, "gst")}
                        style={{ display: "none" }}
                        accept=".jpg, .jpeg, .png, .webp, application/pdf"
                      />
                      <ErrorField>
                        <ErrorMessage name="gst" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup label="Cancelled Cheque">
                      <div className="custom-file-upload-wrapper">
                        <span>Choose File</span>
                        <label
                          htmlFor="cancelledCheque"
                          className="custom-file-upload"
                        >
                          {uploadDocuments.cancelledChequeDocUrl
                            ? uploadDocuments.cancelledChequeDocUrl
                            : "No File Chosen"}
                        </label>
                        <div className="custom-file-preview image-thumbs">
                          
                        {uploadStatus.cancelledCheque === "Pending" && (
                                <CustLoader />
                              )}
                          {uploadDocuments && (
                            <div className="single-thumb">
                           {uploadDocuments && uploadDocuments.cancelledChequeDocUrl &&
                            <button type="button" onClick={()=>deleteBrokerImages("cancelledCheque")}  className="btn btn-danger" style={{width:"30px",height:"30px"}}>
                                <i className="fas fa-trash" style={{"font-size":"10px"}}></i>
                            </button>
                            }
                            <a
                              href={
                                uploadDocuments.cancelledChequeDocUrl
                                  ? uploadDocuments.cancelledChequeDocUrl
                                  : ""
                              }
                              target="_blank"
                            >

                              {!isPDFUrl(
                                uploadDocuments.cancelledChequeDocUrl
                                  ? uploadDocuments.cancelledChequeDocUrl
                                  : ""
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.cancelledChequeDocUrl
                                      ? uploadDocuments.cancelledChequeDocUrl
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn.pixabay.com/photo/2018/05/08/18/46/pdf-3383632_1280.png"
                                  width={200}
                                />
                              )}
                            </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <input
                        id="cancelledCheque"
                        type="file"
                        name="cancelledCheque"
                        onChange={(e) => handleDocUpload(e, "cancelledCheque")}
                        style={{ display: "none" }}
                        accept=".jpg, .jpeg, .png, .webp, application/pdf"
                      />
                      <ErrorField>
                        <ErrorMessage name="cancelledCheque" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-6">
                      <FormGroup label="State">
                        <select
                          name="state"
                          className="form-select"
                          value={selectedState}
                          onChange={chooseState}
                        >
                          <option value="">Select State</option>
                          {!states ? (
                            <option disabled>Loading</option>
                          ) : (
                            states?.map((data) => (
                              <option key={data} value={data}>
                                {data}
                              </option>
                            ))
                          )}
                        </select>
                        <ErrorField>
                          <ErrorMessage name="state" />
                        </ErrorField>
                      </FormGroup>
                    </div>
                    <div class="col-md-6">
                      <FormGroup label="City">
                        <select
                          name="city"
                          value={selectedCity}
                          className="form-select"
                          onChange={chooseCity}
                        >
                          
                          {!city ? (
                            <option value="">Select</option>
                          ) : (
                            city?.map((data) => (
                              <option key={data} value={data}>
                                {data}
                              </option>
                            ))
                          )}
                        </select>
                        <ErrorField>
                          <ErrorMessage name="city" />
                        </ErrorField>
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 d-grid">
                  <Button disabled={isDisabled ? true : false} type="submit">Update</Button>
                </div>
                </div>
                <div className="tab-pane" id="tab-members">
                  <div className="row">
                    <TableContainer>
                      <thead>
                        <tr>
                        <th className="text-center">Sr No.</th>
                        <th className="text-center">Name</th>
                      <th className="text-center">Mobile</th>
                      <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {brokerMembers == null ? (
                          <TableLoader cols={6} />
                        ) : brokerMembers.length === 0 ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              Data not available
                            </td>
                          </tr>
                        ) : (
                          brokerMembers.map((data, index) => (
                            <tr key={data.id}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-center text-capitalize">
                                {data.name}
                              </td>
                              <td className="text-center">{data.mobile}</td>
                              <td className="text-center">
                              {userValidToken ? (
                                  <button type="button" onClick={()=>deleteMember(data.id)} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                ) : (
                                  <div
                                    className="btn btn-danger"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </div>
                                )}
                                </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </TableContainer>
                </div>
                </div>
                </div>

                
              </Form>
            )}
          </Formik>
        )}
      </DataView>
    </ContentLayout>
  );
}
