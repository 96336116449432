import React, { useState, useEffect, useRef } from "react";
import DataView from "../../../../UI/Dashboard/DataView";
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import { useParams, useNavigate } from "react-router-dom";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import Button from "../../../../UI/Form/Button";
import ContentLayout from "../../../../Common/ContentLayout";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { updateStatus } from "../../../../../helpers/JourneyKeys";

export default function SellerDetail() {
  const registryRef = useRef(null);
  const invoiceRef = useRef(null);
  const aadharRef = useRef(null);
  const panRef = useRef(null);
  const propertyRef = useRef(null);
  const loandocRef = useRef(null);
  const allotmentRef = useRef(null);
  const amaRef = useRef(null);
  const earnestRef = useRef(null);
  const atsRef = useRef(null);
  const nocRef = useRef(null);
  const tmRef = useRef(null);
  const registryFinalRef = useRef(null);
  const amfFeeRef = useRef(null);
  const builderBuyerRef = useRef(null);
  const builderNoDueRef = useRef(null);
  const carParkingRef = useRef(null);
  const generalPowerRef = useRef(null);
  const loanforeClosureRef = useRef(null);
  const loanSanctionRef = useRef(null);
  const offerPossessionRef = useRef(null);
  const permissionMortgageRef = useRef(null);
  const previousRegistryRef = useRef(null);
  const provisionalAllotmentRef = useRef(null);
  const transferMemorandumRef = useRef(null);
  const amfPPRef = useRef(null);

  const urlParams = useParams();
  const [journey, setJourney] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [broker, setBroker] = useState([]);
  const [rm,setRM] = useState([])
  const [uploadDocuments, setUploadDocuments] = useState({
    registryImageUrl: "",
    allotmentImageUrl: "",
    loanDocImageUrl: "",
    invoiceImageUrl: "",
    aadharImageUrl: "",
    panImageUrl: "",
    propertyDocImageUrl: "",
    amaDocImageUrl: "",
    earnestImageUrl: "",
    atsImageUrl: "",
    nocImageUrl: "",
    tmImageUrl: "",
    atsSignedImageUrl:"",
    nocSignedImageUrl:"",
    tmSignedImageUrl:"",
    registryFinalImageUrl: "",
    amfFeeImageUrl: "",
    BuilderBuyerImageUrl: "",
    builderNoDuesImageUrl: "",
    carParkingLetterImageUrl: "",
    generalPowerOfAttorneyImageUrl: "",
    loanForeClosureCertificateImageUrl: "",
    loanSanctionLetterImageUrl: "",
    offerOfPossessionImageUrl: "",
    permissionToMortgageImageUrl: "",
    previousRegistryImageUrl: "",
    provisionalAllotmentLetterImageUrl: "",
    transferMemorandumImageUrl: "",
    amfPPImageUrl: "",
  });

  const [uploadStatus, setUploadStatus] = useState({});

  const [updatedJourney, setUpdatedJourney] = useState([]);

  const handleImageUpload = async (e, imageType) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadStatus((prevStatus) => ({
      ...prevStatus,
      [imageType]: "Pending",
    }));

    const formData = new FormData();
    formData.append("file", file);
    // const URL = `https://dev-journey-api.houseeazy.com/images`;
    const URL = `${process.env.REACT_APP_JOURNEY_URL}/images`;
    const reqHeaders = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };


    try {
      const response = await axios.post(URL, formData, reqHeaders);
      const uploadImageRes = response.data;

      // Update the appropriate state based on imageType
      if (imageType === "RegistryDoc") {
        setUploadDocuments((prev) => ({
          ...prev,
          registryImageUrl: uploadImageRes,
        }));
      } else if (imageType === "TokenInvoice") {
        setUploadDocuments((prev) => ({
          ...prev,
          invoiceImageUrl: uploadImageRes,
        }));
      } else if (imageType === "AadharImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          aadharImageUrl: uploadImageRes,
        }));
      } else if (imageType === "PanImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          panImageUrl: uploadImageRes,
        }));
      } else if (imageType === "PropertyDocImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          propertyDocImageUrl: uploadImageRes,
        }));
      } else if (imageType === "AllotmentImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          allotmentImageUrl: uploadImageRes,
        }));
      } else if (imageType === "LoanDocImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          loanDocImageUrl: uploadImageRes,
        }));
      } else if (imageType === "AMAImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          amaDocImageUrl: uploadImageRes,
        }));
      } else if (imageType === "BuilderBuyerImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          BuilderBuyerImage: uploadImageRes,
        }));
      } else if (imageType === "BuilderNoDuesImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          builderNoDuesImageUrl: uploadImageRes,
        }));
      } else if (imageType === "CarParkingLetterImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          carParkingLetterImageUrl: uploadImageRes,
        }));
      } else if (imageType === "GeneralPowerOfAttorneyImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          generalPowerOfAttorneyImageUrl: uploadImageRes,
        }));
      } else if (imageType === "LoanForeClosureCertificateImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          loanForeClosureCertificateImageUrl: uploadImageRes,
        }));
      } else if (imageType === "LoanSanctionLetterImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          loanSanctionLetterImageUrl: uploadImageRes,
        }));
      } else if (imageType === "OfferOfPossessionImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          offerOfPossessionImageUrl: uploadImageRes,
        }));
      } else if (imageType === "PermissionToMortgageImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          permissionToMortgageImageUrl: uploadImageRes,
        }));
      } else if (imageType === "PreviousRegistryImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          previousRegistryImageUrl: uploadImageRes,
        }));
      } else if (imageType === "ProvisionalAllotmentLetterImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          provisionalAllotmentLetterImageUrl: uploadImageRes,
        }));
      } else if (imageType === "TransferMemorandumImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          transferMemorandumImageUrl: uploadImageRes,
        }));
      } else if (imageType === "EarnestImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          earnestImageUrl: uploadImageRes,
        }));
      } else if (imageType === "ATSImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          atsImageUrl: uploadImageRes,
        }));
      } else if (imageType === "ats_signed") {
        setUploadDocuments((prev) => ({
          ...prev,
          atsSignedImageUrl: uploadImageRes,
        }));
      } else if (imageType === "noc_signed") {
        setUploadDocuments((prev) => ({
          ...prev,
          nocSignedImageUrl: uploadImageRes,
        }));
      } else if (imageType === "tm_signed") {
        setUploadDocuments((prev) => ({
          ...prev,
          tmSignedImageUrl: uploadImageRes,
        }));
      } else if (imageType === "NOCImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          nocImageUrl: uploadImageRes,
        }));
      } else if (imageType === "TMImage") {
        setUploadDocuments((prev) => ({ ...prev, tmImageUrl: uploadImageRes }));
      } else if (imageType === "RegistryFinalImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          registryFinalImageUrl: uploadImageRes,
        }));
      } else if (imageType === "AmfFeeImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          amfFeeImageUrl: uploadImageRes,
        }));
      } else if (imageType === "AMFPPImage") {
        setUploadDocuments((prev) => ({
          ...prev,
          amfPPImageUrl: uploadImageRes,
        }));
      }
      // Set upload status to done
      setUploadStatus((prevStatus) => ({
        ...prevStatus,
        [imageType]: "Done",
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };


  const userValidToken = localStorage.getItem("userToken");

  useEffect(() => {
    (async () => {
      const journeyData = await JourneyAjax({
        url: `/pj/admin/${urlParams.JourneyId}`,
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        "x-user": userValidToken ||  null,
        token: true,
      });
      if (journeyData && journeyData.data && journeyData.data.data) {
        const originalJourneyData = journeyData.data.data;
        setJourney(originalJourneyData); // Store original data in journey state
        
        // Deep clone the original data before modifying it
        const updatedJourneyData = JSON.parse(
          JSON.stringify(originalJourneyData)
        );
        // Change Status pending -->> inprogress && inprogress -->> completed
        const updatedJourneyTE = updateStatus(updatedJourneyData);
        setUpdatedJourney(updatedJourneyTE); // Store modified data in updatedJourney state
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const rmData = await JourneyAjax({
        url: `/relation-managers`,
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      });
      if (rmData  && rmData.data) {
        setRM(rmData.data.data.relationManagers); 
      }
    })();
  }, []);


  useEffect(() => {
    (async () => {
      const brokerData = await JourneyAjax({
        url: `/admin/brokers?filter={%22skip%22%3A0}`,
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        "x-user": userValidToken || null,
      });
      if (brokerData  && brokerData.data) {
        setBroker(brokerData.data.data.brokers); // Store brokerData state
      }
    })();
  }, []);


  const addJourney = async (values, event) => {
    const data = {
      ...(values.slug && { slug: values.slug }),
      broker: values.broker,
      house_inspection_date: values.house_inspection_date,
      house_inspection_status: values.house_inspection_status,
      house_inspection_time: values.house_inspection_time,

      inspection_verdict_propertyAge: values.inspection_verdict_propertyAge,
      inspection_verdict_greenArea: values.inspection_verdict_greenArea,
      inspection_verdict_construction: values.inspection_verdict_construction,
      inspection_verdict_amenities: values.inspection_verdict_amenities,
      inspection_verdict_connectivity: values.inspection_verdict_connectivity,
      inspection_verdict_apartment_condition:values.inspection_verdict_apartment_condition,
      inspection_verdict_management: values.inspection_verdict_management,
      inspection_verdict_status: values.inspection_verdict_status,

      price: values.price,
      price_status: values.price_status,
      keyHandover:values.keyHandover?values.keyHandover:'',

      token_bank_details_email: values.token_bank_details_email,
      token_bank_details_accountNumber: values.token_bank_details_accountNumber,
      token_bank_details_accountName: values.token_bank_details_accountName,
      token_bank_details_accountifsc: values.token_bank_details_accountifsc,
      token_bank_details_registry: values.token_bank_details_registry,
      // token_bank_details_status: values.token_bank_details_status,

      token_documentation_aadhar: values.token_documentation_aadhar,
      token_documentation_pan: values.token_documentation_pan,
      token_documentation_registry: values.token_documentation_registry,
      token_documentation_allotment_letter:
        values.token_documentation_allotment_letter,
      token_documentation_property_document:
        values.token_documentation_property_document,
      token_documentation_isLoan: values.token_documentation_isLoan,
      token_documentation_loan_document:
        values.token_documentation_loan_document,
      token_documentation_loan_amount: values.token_documentation_loan_amount,
      token_documentation_status: values.token_documentation_status,

      token_money_amount: values.token_money_amount,
      token_money_invoice: values.token_money_invoice,
      token_money_status: values.token_money_status,

      ama_doc_link: values.ama_doc_link,
      ama_isSigned: values.ama_isSigned,
      ama_status: values.ama_status,
      relationManagerId:values.relationManager?values.relationManager:'',

      property_document_builderBuyerAgreement: values.property_document_builderBuyerAgreement,
      property_document_builderNoDues: values.property_document_builderNoDues,
      property_document_carParkingLetter: values.property_document_carParkingLetter,
      property_document_generalPowerOfAttorney: values.property_document_generalPowerOfAttorney,
      property_document_loanForeClosureCertificate: values.property_document_loanForeClosureCertificate,
      property_document_loanSanctionLetter: values.property_document_loanSanctionLetter,
      property_document_offerOfPossession: values.property_document_offerOfPossession,     
      property_document_permissionToMortgage: values.property_document_permissionToMortgage,  
      property_document_previousRegistry: values.property_document_previousRegistry,  
      property_document_provisionalAllotmentLetter: values.property_document_provisionalAllotmentLetter,  
      property_document_transferMemorandum: values.property_document_transferMemorandum,   
      property_document_status: values.property_document_status, 


      advance_earnest_money_final_price:
        values.advance_earnest_money_final_price,
      advance_earnest_money_token_amount:
        values.advance_earnest_money_token_amount,
      advance_earnest_money_balance_advance:
        values.advance_earnest_money_balance_advance,
      advance_earnest_money_status: values.advance_earnest_money_status,

      advance_bank_details_email: values.advance_bank_details_email,
      advance_bank_details_primaryAccountName:
        values.advance_bank_details_primaryAccountName,
      advance_bank_details_primaryAccountNumber:
        values.advance_bank_details_primaryAccountNumber,
      advance_bank_details_primaryAccountIfsc:
        values.advance_bank_details_primaryAccountIfsc,

      advance_bank_details_secondaryAccountName:
        values.advance_bank_details_secondaryAccountName,
      advance_bank_details_secondaryAccountNumber:
        values.advance_bank_details_secondaryAccountNumber,
      advance_bank_details_secondaryAccountIfsc:
        values.advance_bank_details_secondaryAccountIfsc,

      advance_bank_details_tertiaryAccountName:
        values.advance_bank_details_tertiaryAccountName,
      advance_bank_details_tertiaryAccountNumber:
        values.advance_bank_details_tertiaryAccountNumber,
      advance_bank_details_tertiaryAccountIfsc:
        values.advance_bank_details_tertiaryAccountIfsc,
      advance_bank_details_status: values.advance_bank_details_status,

      final_documentation_status: values.final_documentation_status,
      earnest_money_doc_link: values.earnest_money_doc_link,
      earnest_status: values.earnest_status,

      ats_draft: values.ats_draft,
      ats_refund_status: Number(values.ats_refund_status),
      ats_signed: values.ats_signed,
      noc_signed: values.noc_signed,
      tm_signed: values.tm_signed,
      ats_status: values.ats_status,

      noc_status: values.noc_status,
      noc: values.noc,
      tm: values.tm,
      tm_status: values.tm_status,

      amf_paid_at_registry: values.amf_paid_at_registry,
      amf_proof_of_payment: values.amf_proof_of_payment,
      amf_status: values.amf_status,
      amf_fee: values.amf_fee,
      amf_fee_invoice: values.amf_fee_invoice,
      amf_mode_of_payment: values.amf_mode_of_payment,
      amf_fee_status: values.amf_fee_status,

      registryDate: values.registryDate,
      registryTime: values.registryTime,
      registry_document: values.registry_document,
      registry_status: values.registry_status,
      overall_journey_status: values.overall_journey_status,
    };
    const formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
      // if(data[key] === "" && key != 'price' && key != 'price_status' && key != 'advance_bank_details_primaryAccountName' && key != 'advance_bank_details_primaryAccountNumber' && key != 'advance_bank_details_primaryAccountIfsc' && key != 'advance_bank_details_secondaryAccountName' && key != 'advance_bank_details_secondaryAccountNumber' && key != 'advance_bank_details_secondaryAccountIfsc' && key != 'advance_bank_details_tertiaryAccountName'
      //  && key != 'advance_bank_details_tertiaryAccountNumber'
      //  && key != 'advance_bank_details_tertiaryAccountIfsc'
      //  ) {
      //     toast.error(`Some Fields are Empty please check (${key})`,{
      //         toastId: 'EmptyFields'
      //     })
      //     return false
      // }
    }

    const userDetails = {
      brokerId: data.broker,
      relationManagerId:data.relationManagerId,
      keyHandover:data.keyHandover
    }

    const houseInspectionData = {
      house_inspection: {
        date: data.house_inspection_date,
        time: data.house_inspection_time,
        // status: data.house_inspection_status,
        status: updatedJourney && updatedJourney.house_inspection.status,
        verdict: {
          propertyAge: Number(data.inspection_verdict_propertyAge),
          greenArea: Number(data.inspection_verdict_greenArea),
          construction: Number(data.inspection_verdict_construction),
          amenities: Number(data.inspection_verdict_amenities),
          apartmentCondition:Number(data.inspection_verdict_apartment_condition),
          connectivity:Number(data.inspection_verdict_connectivity),
          management:Number(data.inspection_verdict_management),
          status: data.inspection_verdict_status,
        },
      },
      price: {
        amount: data.price,
        status: updatedJourney.price.status,
      },
    };



    // const tokenBankDetailsData = {
    //   token_bank_details: {
    //     email: data.token_bank_details_email,
    //     accountNumber: data.token_bank_details_accountNumber,
    //     accountName: data.token_bank_details_accountName,
    //     ifsc: data.token_bank_details_accountifsc,
    //     registry:
    //       uploadDocuments.registryImageUrl || data.token_bank_details_registry,
    //     status: updatedJourney.token_bank_details.status,
    //   },
    // };

    const tokenDocumentationData = {
      token_documentation: {
        tokenBankDetails: {
          email: data.token_bank_details_email,
          accountNumber: data.token_bank_details_accountNumber,
          accountName: data.token_bank_details_accountName,
          ifsc: data.token_bank_details_accountifsc,
          // status: updatedJourney.token_bank_details.status,
        },
        status: updatedJourney.token_documentation.status,
        aadhar:
          uploadDocuments.aadharImageUrl || data.token_documentation_aadhar,
        pan: uploadDocuments.panImageUrl || data.token_documentation_pan,
        property_document:
          uploadDocuments.propertyDocImageUrl ||
          data.token_documentation_property_document,
        registry:
          uploadDocuments.registryImageUrl || data.token_documentation_registry,
        allotment_letter:
          uploadDocuments.allotmentImageUrl ||
          data.token_documentation_allotment_letter,
        isLoan: data.token_documentation_isLoan,
        loan_amount: data.token_documentation_loan_amount,
        loan_document:
          uploadDocuments.loanDocImageUrl ||
          data.token_documentation_loan_document,
      },
      token_money: {
        amount: data.token_money_amount,
        invoice: uploadDocuments.invoiceImageUrl[0],
        status: updatedJourney.token_money.status,
      },
    };

    const propertyDocumentsData = {
      property_document: {
        builderBuyerAgreement: uploadDocuments.BuilderBuyerImageUrl || data.property_document_builderBuyerAgreement,
        builderNoDues: uploadDocuments.builderNoDuesImageUrl || data.property_document_builderNoDues,
        carParkingLetter: uploadDocuments.carParkingLetterImageUrl || data.property_document_carParkingLetter,
        generalPowerOfAttorney: uploadDocuments.generalPowerOfAttorneyImageUrl || data.property_document_generalPowerOfAttorney,
        loanForeClosureCertificate: uploadDocuments.loanForeClosureCertificateImageUrl || data.property_document_loanForeClosureCertificate,
        loanSanctionLetter: uploadDocuments.loanSanctionLetterImageUrl || data.property_document_loanSanctionLetter,
        offerOfPossession: uploadDocuments.offerOfPossessionImageUrl || data.property_document_offerOfPossession,
        permissionToMortgage: uploadDocuments.permissionToMortgageImageUrl || data.property_document_permissionToMortgage,
        previousRegistry: uploadDocuments.previousRegistryImageUrl || data.property_document_previousRegistry,
        provisionalAllotmentLetter: uploadDocuments.provisionalAllotmentLetterImageUrl || data.property_document_provisionalAllotmentLetter,
        transferMemorandum: uploadDocuments.transferMemorandumImageUrl || data.property_document_transferMemorandum,
        status: updatedJourney.property_document.status
      },
      ama: {
        status: updatedJourney.ama.status,
      },
    }

    const amaData = {
      ama: {
        // status: updatedJourney.ama.status,
        doc_link: uploadDocuments.amaDocImageUrl[0],
      },
      advance_earnest_money: {
        status: updatedJourney.advance_earnest_money.status,
        final_price: data.advance_earnest_money_final_price,
        token_amount: data.advance_earnest_money_token_amount,
        balance_advance: data.advance_earnest_money_balance_advance,
      },
    };

    const advanceBankDetailsData = {
      advance_bank_details: {
        primary: {
          accountName: data.advance_bank_details_primaryAccountName,
          accountNumber: data.advance_bank_details_primaryAccountNumber,
          ifsc: data.advance_bank_details_primaryAccountIfsc,
        },
        secondary: {
          accountName: data.advance_bank_details_secondaryAccountName,
          accountNumber: data.advance_bank_details_secondaryAccountNumber,
          ifsc: data.advance_bank_details_secondaryAccountIfsc,
        },
        tertiary: {
          accountName: data.advance_bank_details_tertiaryAccountName,
          accountNumber: data.advance_bank_details_tertiaryAccountNumber,
          ifsc: data.advance_bank_details_tertiaryAccountIfsc,
        },
        email: data.advance_bank_details_email,
        status: updatedJourney.advance_bank_details.status,
      },
      final_documentation: {
        status: updatedJourney.final_documentation.status,
        earnest_money_doc_link: uploadDocuments.earnestImageUrl[0],
        earnest_status: data.earnest_status,
        // ats: uploadDocuments.atsImageUrl[0],
        ats_status: data.ats_status,
        // noc_status: data.noc_status,
      },
    };

    const atsData = {
      final_documentation: {
        earnest_status: data.earnest_status,
        ats_draft: uploadDocuments.atsImageUrl[0],
        refundStatus: data.ats_refund_status === 0 ? false : true,
        // isAtsDraft: data.ats_signed? true : false,
        // ats_status: data.ats_status,
        ats_signed:uploadDocuments.atsSignedImageUrl[0],
        noc_signed: uploadDocuments.nocSignedImageUrl[0],
        tm_signed:uploadDocuments.tmSignedImageUrl[0],
        amf_status: data.amf_status,
      },
    };

    // const finalDoc1Data = {
    //   final_documentation: {
    //     noc_status: data.noc_status,
    //     tm_status: data.tm_status,
    //   },
    // };

    // const finalDoc2Data = {
    //   final_documentation: {
    //     tm_status: data.tm_status,
    //     amf_status: data.amf_status,
    //   },
    // };

    const finalDoc3Data = {
      final_documentation: {
        amf: {
          fee_invoice: uploadDocuments.amfFeeImageUrl[0],
          mode_of_payment: data.amf_mode_of_payment,
          fee_status: data.amf_fee_status,
          fee: data.fee,
        },
        amf_status: data.amf_status,
        amf_paid_at_registry: data.amf_paid_at_registry,
        // amf_proof_of_payment: uploadDocuments.amfProofOfPayment,
        registry_status: data.registry_status,
        // status: updatedJourney.final_documentation.status,
      },
    };

    const finalDoc4Data = {
      final_documentation: {
        registry_document: uploadDocuments.registryFinalImageUrl[0],
        registryDate: data.registryDate,
        registryTime: data.registryTime,
      },
    };

    const overallJourneyData = {
      final_documentation: {
        status: data.final_documentation_status,
      },
      status: data.overall_journey_status,
    };

    const reqBodyData = {
      userDetails,
      houseInspectionData,
      tokenDocumentationData,
      propertyDocumentsData,
      amaData,
      advanceBankDetailsData,
      atsData,
      finalDoc3Data,
      finalDoc4Data,
      overallJourneyData,
    };

    

    function getData(activeTab, data) {
      switch (activeTab) {
        case 0:
          return userDetails;
        case 1:
          return houseInspectionData;
        case 2:
          return tokenDocumentationData;
        case 3:
          return propertyDocumentsData;
        case 4:
          return amaData;  
        case 5:
          return advanceBankDetailsData;
        case 6:
          return atsData;
        case 7:
          return finalDoc3Data;
        case 8:
          return finalDoc4Data;
        case 9:
          return overallJourneyData;
        default:
          return null;
      }
    }

    const update = await JourneyAjax({
      url: `/pj/admin/${urlParams.JourneyId}`,
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "x-user": userValidToken ||  null,
      method: "PATCH",
      token: true,
      data: getData(activeTab, reqBodyData),
      loader: true,
    });


    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }

    if (update.data.code === 200) {
      toast.success(update.data.message);
      // setTimeout(function() {
      //   window.location.reload();
      // }, 500);
    }
  };

  // BUTTON DISABLED STATUS

  // const userDetailsStatus = (Object.keys(journey).length && journey.brokerId)
  // ? journey.brokerId.length > 0
  // : null;

  const houseInspectionStatus = Object.keys(journey).length
    ? journey.house_inspection.status === "completed"
    : null;

  // const tokenBankDetailsDataStatus = Object.keys(journey).length
  //   ? journey.token_bank_details.status === "inprogress"
  //   : null;

  const tokenDocumentationStatus = Object.keys(journey).length
    ? journey.token_documentation.status === "completed" &&
      (journey.token_money.status === "inprogress" ||
        journey.token_money.status === "completed") 
    : null;

  const propertyDocumentsStatus = Object.keys(journey).length
    ? journey.property_document.status === "completed" : null
      

  const amaStatus = Object.keys(journey).length
    ? journey.ama.status === "completed" : null;

  const advanceBankDetailsStatus = Object.keys(journey).length
    ? journey.advance_bank_details.status === "completed"
    : null;

  const atsStatus = Object.keys(journey).length
    ? journey.final_documentation.ats_draft &&
      journey.final_documentation.ats_draft.length > 0
    : null;

  // const amfStatus = Object.keys(journey).length
  //   ? journey.final_documentation.amf &&
  //     journey.final_documentation.amf.fee_invoice &&
  //     journey.final_documentation.amf.fee_invoice.length > 0
  //   : null;
        

  // const finalDoc1Status = Object.keys(journey).length
  //   ? journey.final_documentation.noc_status === "completed"
  //   : null;

  // const finalDoc2Status = Object.keys(journey).length
  //   ? journey.final_documentation.tm_status === "completed"
  //   : null;

  const finalDoc3Status = Object.keys(journey).length
    ? journey.final_documentation.amf &&
      journey.final_documentation.amf.fee_invoice &&
      journey.final_documentation.amf.fee_invoice.length > 0
    : null;

  const finalDoc4Status = Object.keys(journey).length
    ? journey.final_documentation.registry_document &&
      journey.final_documentation.registry_document.length > 0
    : null;

  const overAllJourneyStatus = Object.keys(journey).length
    ? journey.status === "completed"
    : null;

  // check image URL is pdf or not
  function isPDFUrl(url) {
    return url && url.toLowerCase().endsWith(".pdf");
  }


  return (
    <>
      <ContentLayout title="Seller Journey">
        <Helmet>
          <title>Seller Journey</title>
        </Helmet>
        <DataView title="Infomation">
          {Object.keys(updatedJourney).length === 0 ? (
            <DataLoader />
          ) : (
            <Formik
              initialValues={{
                broker: updatedJourney&&updatedJourney.broker&&updatedJourney.broker.id?updatedJourney.broker.id:'',
                house_inspection_date:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.date
                    ? updatedJourney.house_inspection.date
                    : "",
                house_inspection_time:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.time
                    ? updatedJourney.house_inspection.time
                    : "",
                house_inspection_status:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.status,
                  relationManager:updatedJourney.relationManagerId?updatedJourney.relationManagerId:'',
                keyHandover:journey.keyHandover?journey.keyHandover:'',
                inspection_verdict_propertyAge:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.verdict &&
                  updatedJourney.house_inspection.verdict.propertyAge,
                  inspection_verdict_greenArea:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.verdict &&
                  updatedJourney.house_inspection.verdict.greenArea,
                  inspection_verdict_construction:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.verdict &&
                  updatedJourney.house_inspection.verdict.construction,
                  inspection_verdict_amenities:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.verdict &&
                  updatedJourney.house_inspection.verdict.amenities,
                  inspection_verdict_connectivity:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.verdict &&
                  updatedJourney.house_inspection.verdict.connectivity,
                  inspection_verdict_management:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.verdict &&
                  updatedJourney.house_inspection.verdict.management,
                inspection_verdict_status:
                  updatedJourney.house_inspection &&
                  updatedJourney.house_inspection.verdict &&
                  updatedJourney.house_inspection.verdict.status,
                inspection_verdict_apartment_condition: updatedJourney.house_inspection &&
                updatedJourney.house_inspection.verdict &&
                updatedJourney.house_inspection.verdict.apartmentCondition?updatedJourney.house_inspection.verdict.apartmentCondition:4,
                price: updatedJourney.price && updatedJourney.price.amount,
                price_status:
                  updatedJourney.price && updatedJourney.price.status,

                token_bank_details_email:
                  updatedJourney.token_documentation && updatedJourney.token_documentation.tokenBankDetails &&
                  updatedJourney.token_documentation.tokenBankDetails.email,
                token_bank_details_accountNumber:
                  updatedJourney.token_documentation && updatedJourney.token_documentation.tokenBankDetails &&
                  updatedJourney.token_documentation.tokenBankDetails.accountNumber,
                token_bank_details_accountName:
                  updatedJourney.token_documentation && updatedJourney.token_documentation.tokenBankDetails &&
                  updatedJourney.token_documentation.tokenBankDetails.accountName,
                token_bank_details_accountifsc:
                  updatedJourney.token_documentation && updatedJourney.token_documentation.tokenBankDetails &&
                  updatedJourney.token_documentation.tokenBankDetails.ifsc,
                token_bank_details_registry:
                  updatedJourney.token_documentation && updatedJourney.token_documentation.tokenBankDetails &&
                  updatedJourney.token_documentation.tokenBankDetails.registry,
                // token_bank_details_status:
                //   updatedJourney.token_documentation && updatedJourney.token_documentation.tokenBankDetails &&
                //   updatedJourney.token_bank_details.status,


                token_documentation_aadhar:
                  updatedJourney.token_documentation &&
                  updatedJourney.token_documentation.aadhar,
                token_documentation_pan:
                  updatedJourney.token_documentation &&
                  updatedJourney.token_documentation.pan,
                token_documentation_property_document:
                  updatedJourney.token_documentation &&
                  updatedJourney.token_documentation.property_document,
                token_documentation_registry:
                  updatedJourney.token_documentation &&
                  updatedJourney.token_documentation.registry,
                token_documentation_allotment_letter:
                  updatedJourney.token_documentation &&
                  updatedJourney.token_documentation.allotment_letter,
                token_documentation_isLoan:
                  updatedJourney.token_documentation &&
                  updatedJourney.token_documentation.isLoan,
                token_documentation_loan_document:
                  updatedJourney.token_documentation &&
                  updatedJourney.token_documentation.loan_document,
                token_documentation_loan_amount:
                  updatedJourney.token_documentation &&
                  updatedJourney.token_documentation.loan_amount,
                token_documentation_status:
                  updatedJourney.token_documentation &&
                  updatedJourney.token_documentation.status,  

                token_money_amount:
                  updatedJourney.token_money &&
                  updatedJourney.token_money.amount,
                // token_money_invoice: updatedJourney.token_money && updatedJourney.token_money.invoice.length > 0 ? updatedJourney.token_money.invoice : "",
                token_money_invoice: "",
                token_money_status:
                  updatedJourney.token_money &&
                  updatedJourney.token_money.status,

                ama_doc_link: updatedJourney.ama && updatedJourney.ama.doc_link,
                ama_isSigned: updatedJourney.ama && updatedJourney.ama.isSigned,
                ama_status: updatedJourney.ama && updatedJourney.ama.status,

                property_document_builderBuyerAgreement: updatedJourney.property_document && updatedJourney.property_document.builderBuyerAgreement,
                property_document_builderNoDues: updatedJourney.property_document && updatedJourney.property_document.builderNoDues,
                property_document_carParkingLetter: updatedJourney.property_document && updatedJourney.property_document.carParkingLetter,
                property_document_generalPowerOfAttorney: updatedJourney.property_document && updatedJourney.property_document.generalPowerOfAttorney,
                property_document_loanForeClosureCertificate: updatedJourney.property_document && updatedJourney.property_document.loanForeClosureCertificate,
                property_document_loanSanctionLetter: updatedJourney.property_document && updatedJourney.property_document.loanSanctionLetter,
                property_document_offerOfPossession: updatedJourney.property_document && updatedJourney.property_document.offerOfPossession,               
                property_document_permissionToMortgage: updatedJourney.property_document && updatedJourney.property_document.permissionToMortgage,  
                property_document_previousRegistry: updatedJourney.property_document && updatedJourney.property_document.previousRegistry,  
                property_document_provisionalAllotmentLetter: updatedJourney.property_document && updatedJourney.property_document.provisionalAllotmentLetter,  
                property_document_transferMemorandum: updatedJourney.property_document && updatedJourney.property_document.transferMemorandum,   
                property_document_status: updatedJourney.property_document && updatedJourney.property_document.status, 

                advance_earnest_money_status:
                  updatedJourney.advance_earnest_money &&
                  updatedJourney.advance_earnest_money.status,
                advance_earnest_money_final_price:
                  updatedJourney.advance_earnest_money &&
                  updatedJourney.advance_earnest_money.final_price,
                advance_earnest_money_token_amount:
                  updatedJourney.advance_earnest_money &&
                  updatedJourney.advance_earnest_money.token_amount,
                advance_earnest_money_balance_advance:
                  updatedJourney.advance_earnest_money &&
                  updatedJourney.advance_earnest_money.balance_advance,

                advance_bank_details_email:
                  updatedJourney.advance_bank_details &&
                  updatedJourney.advance_bank_details.email
                    ? updatedJourney.advance_bank_details.email
                    : "",
                advance_bank_details_primaryAccountName:
                  updatedJourney.advance_bank_details &&
                  Object.keys(updatedJourney.advance_bank_details).includes(
                    "primary"
                  )
                    ? updatedJourney.advance_bank_details.primary.accountName
                    : "",
                advance_bank_details_primaryAccountNumber:
                  updatedJourney.advance_bank_details &&
                  Object.keys(updatedJourney.advance_bank_details).includes(
                    "primary"
                  )
                    ? updatedJourney.advance_bank_details.primary.accountNumber
                    : "",
                advance_bank_details_primaryAccountIfsc:
                  updatedJourney.advance_bank_details &&
                  Object.keys(updatedJourney.advance_bank_details).includes(
                    "primary"
                  )
                    ? updatedJourney.advance_bank_details.primary.ifsc
                    : "",

                advance_bank_details_secondaryAccountName:
                  updatedJourney.advance_bank_details &&
                  Object.keys(updatedJourney.advance_bank_details).includes(
                    "secondary"
                  )
                    ? updatedJourney.advance_bank_details.secondary.accountName
                    : "",
                advance_bank_details_secondaryAccountNumber:
                  updatedJourney.advance_bank_details &&
                  Object.keys(updatedJourney.advance_bank_details).includes(
                    "secondary"
                  )
                    ? updatedJourney.advance_bank_details.secondary
                        .accountNumber
                    : "",
                advance_bank_details_secondaryAccountIfsc:
                  updatedJourney.advance_bank_details &&
                  Object.keys(updatedJourney.advance_bank_details).includes(
                    "secondary"
                  )
                    ? updatedJourney.advance_bank_details.secondary.ifsc
                    : "",

                advance_bank_details_tertiaryAccountName:
                  updatedJourney.advance_bank_details &&
                  Object.keys(updatedJourney.advance_bank_details).includes(
                    "tertiary"
                  )
                    ? updatedJourney.advance_bank_details.tertiary.accountName
                    : "",
                advance_bank_details_tertiaryAccountNumber:
                  updatedJourney.advance_bank_details &&
                  Object.keys(updatedJourney.advance_bank_details).includes(
                    "tertiary"
                  )
                    ? updatedJourney.advance_bank_details.tertiary.accountNumber
                    : "",
                advance_bank_details_tertiaryAccountIfsc:
                  updatedJourney.advance_bank_details &&
                  Object.keys(updatedJourney.advance_bank_details).includes(
                    "tertiary"
                  )
                    ? updatedJourney.advance_bank_details.tertiary.ifsc
                    : "",
                advance_bank_details_status:
                  updatedJourney.advance_bank_details &&
                  updatedJourney.advance_bank_details.status,

                final_documentation_status:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.status,
                earnest_money_doc_link:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.earnest_money_doc_link,
                earnest_status:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.earnest_status,
                ats_draft:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.ats_draft,
                noc_signed:updatedJourney.final_documentation &&
                updatedJourney.final_documentation.noc_signed === true ? 1: 0,
                tm_signed:updatedJourney.final_documentation &&
                updatedJourney.final_documentation.noc_signed === true ? 1: 0,
                ats_refund_status:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.refundStatus === true ? 1 : 0, 
                ats_signed:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.ats_signed === true ? 1 : 0,    
                ats_status:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.ats_status,
                noc_status:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.noc_status
                    ? updatedJourney.final_documentation.noc_status
                    : "pending",
                noc:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.noc,
                tm_status:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.tm_status
                    ? updatedJourney.final_documentation.tm_status
                    : "pending",
                tm:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.tm,

                amf_status:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.amf_status
                    ? updatedJourney.final_documentation.amf_status
                    : "pending",
                amf_fee:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.amf &&
                  updatedJourney.final_documentation.amf.fee,
                amf_fee_invoice:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.amf &&
                  updatedJourney.final_documentation.amf.fee_invoice,
                amf_mode_of_payment:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.amf &&
                  updatedJourney.final_documentation.amf.mode_of_payment,
                amf_fee_status:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.amf &&
                  updatedJourney.final_documentation.amf.fee_status,
                amf_paid_at_registry:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.amf &&
                  updatedJourney.final_documentation.amf_paid_at_registry,
                amf_proof_of_payment: 
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.amf &&
                  updatedJourney.final_documentation.proofOfPayment,
                registryDate:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.registryDate,
                registryTime:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.registryTime,
                registry_status:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.registry_status
                    ? updatedJourney.final_documentation.registry_status
                    : "pending",
                registry_document:
                  updatedJourney.final_documentation &&
                  updatedJourney.final_documentation.registry_document
                    ? updatedJourney.final_documentation.registry_document
                    : "",

                overall_journey_status: updatedJourney && updatedJourney.status
              }}
              onSubmit={(values, event) => {
                addJourney(values, event);
              }}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <ul id="#journey-tabs" className="nav nav-tabs">
                    <li onClick={() => setActiveTab(0)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        href="#tab-user-details"
                        // style={}
                        className="nav-link active"
                      >
                        User Details
                      </a>
                    </li>
                    <li onClick={() => setActiveTab(1)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.house_inspection.status != "inprogress"
                          ? {
                              className: "nav-link",
                              href: "#tab-house-inspection",
                            }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.house_inspection.status ===
                              "inprogress" &&
                            "#d9d9d9",
                        }}
                        // className="nav-link active"
                      >
                        House Inspection
                      </a>
                    </li>
                    <li onClick={() => setActiveTab(2)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.token_documentation.status !=
                          "inprogress"
                          ? {
                              className: "nav-link",
                              href: "#tab-token-documentation",
                            }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.token_documentation.status ===
                              "inprogress" &&
                            "#d9d9d9",
                        }}
                      >
                        Token Documentation
                      </a>
                    </li>

                    <li onClick={() => setActiveTab(3)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        className="nav-link"
                        // href="#tab-property-documents"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.property_document.status != "inprogress"
                          ? {
                              className: "nav-link",
                              href: "#tab-property-documents",
                            }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.property_document.status ===
                              "inprogress" &&
                            "#d9d9d9",
                        }}
                      >
                        Property Documents
                      </a>
                    </li>

                    <li onClick={() => setActiveTab(4)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.ama.status != "inprogress"
                          ? { className: "nav-link", href: "#tab-token-ama" }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.ama.status === "inprogress" &&
                            "#d9d9d9",
                        }}
                      >
                        AMA
                      </a>
                    </li>

                    

                    <li onClick={() => setActiveTab(5)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.advance_bank_details.status !=
                          "inprogress"
                          ? {
                              className: "nav-link",
                              href: "#tab-advance-bank-details",
                            }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.advance_bank_details.status ===
                              "inprogress" &&
                            "#d9d9d9",
                        }}
                      >
                        Advance Bank Details
                      </a>
                    </li>
                    <li onClick={() => setActiveTab(6)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.final_documentation.status !=
                          "inprogress" &&
                        (updatedJourney.final_documentation.ats_status ==
                          "inprogress" ||
                          updatedJourney.final_documentation.ats_status ==
                            "completed")
                          ? { className: "nav-link", href: "#tab-ats" }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.final_documentation.status !=
                              "inprogress" &&
                            (updatedJourney.final_documentation.ats_status ==
                              "inprogress" ||
                              updatedJourney.final_documentation.ats_status ==
                                "completed")
                              ? ""
                              : "#d9d9d9",
                        }}
                      >
                        ATS
                      </a>
                    </li>
                    {/* <li onClick={() => setActiveTab(7)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.final_documentation.status !=
                          "inprogress" &&
                        (updatedJourney.final_documentation.noc_status ==
                          "inprogress" ||
                          updatedJourney.final_documentation.noc_status ==
                            "completed")
                          ? {
                              className: "nav-link",
                              href: "#tab-final-documentation-2",
                            }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.final_documentation.status !=
                              "inprogress" &&
                            (updatedJourney.final_documentation.noc_status ==
                              "inprogress" ||
                              updatedJourney.final_documentation.noc_status ==
                                "completed")
                              ? ""
                              : "#d9d9d9",
                        }}
                      >
                        NOC
                      </a>
                    </li>
                    <li onClick={() => setActiveTab(8)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.final_documentation.status !=
                          "inprogress" &&
                        (updatedJourney.final_documentation.tm_status ==
                          "inprogress" ||
                          updatedJourney.final_documentation.tm_status ==
                            "completed")
                          ? {
                              className: "nav-link",
                              href: "#tab-final-documentation-3",
                            }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.final_documentation.status !=
                              "inprogress" &&
                            (updatedJourney.final_documentation.tm_status ==
                              "inprogress" ||
                              updatedJourney.final_documentation.tm_status ==
                                "completed")
                              ? ""
                              : "#d9d9d9",
                        }}
                      >
                        TM
                      </a>
                    </li> */}
                   
                    <li onClick={() => setActiveTab(7)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.final_documentation.status !=
                          "inprogress" ||
                          (updatedJourney.final_documentation.amf_status ==
                            "inprogress" ||
                            updatedJourney.final_documentation.amf_status ==
                              "completed")
                          ? {
                              className: "nav-link",
                              href: "#tab-final-documentation-4",
                            }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.final_documentation.status !=
                              "inprogress" ||
                            (updatedJourney.final_documentation.amf_status ==
                              "inprogress" ||
                              updatedJourney.final_documentation.amf_status ==
                                "completed")
                              ? ""
                              : "#d9d9d9",
                        }}
                      >
                        AMF
                      </a>
                    </li>

                    <li onClick={() => setActiveTab(8)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.final_documentation.status !=
                          "inprogress" &&
                        (updatedJourney.final_documentation.registry_status ==
                          "inprogress" ||
                          updatedJourney.final_documentation.registry_status ==
                            "completed")
                          ? {
                              className: "nav-link",
                              href: "#tab-final-documentation-5",
                            }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.final_documentation.status !=
                              "inprogress" &&
                            (updatedJourney.final_documentation
                              .registry_status == "inprogress" ||
                              updatedJourney.final_documentation
                                .registry_status == "completed")
                              ? ""
                              : "#d9d9d9",
                        }}
                      >
                        Registry
                      </a>
                    </li>
                    <li onClick={() => setActiveTab(9)} className="nav-item">
                      <a
                        data-bs-toggle="tab"
                        {...(Object.keys(updatedJourney).length &&
                        updatedJourney.final_documentation.status ==
                          "completed" &&
                        (updatedJourney.status == "inprogress" ||
                          updatedJourney.status == "completed")
                          ? {
                              className: "nav-link",
                              href: "#tab-journey-status",
                            }
                          : { className: "nav-link disabled" })}
                        style={{
                          backgroundColor:
                            Object.keys(updatedJourney).length &&
                            updatedJourney.final_documentation.status ==
                              "completed" &&
                            (updatedJourney.status == "inprogress" ||
                              updatedJourney.status == "completed")
                              ? ""
                              : "#d9d9d9",
                        }}
                      >
                        Final Step
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {/* TAB 0 */}
                    <div className="tab-pane active" id="tab-user-details">
                    <h4>Please Select Broker</h4>
                    <div className="row my-4">
                        <div className="col-md-4">
                          <p> <FormGroup label="Broker" required>
                        <Field as="select" name="broker" className='form-select'>
                            <option value="" >Select</option>
                            {broker === null ? <option disabled>Loading</option> : broker.map((data)=>(
                            <option key={data.id} value={data.id}>{data.name}</option>
                          ))}
                        </Field>
                        <ErrorField><ErrorMessage name="bhk"/></ErrorField>
                      </FormGroup></p>
                        </div>
                        <div className="col-md-4">
                          <p> <FormGroup label="Relation Managers" required>
                        <Field as="select" name="relationManager" className='form-select'>
                            <option value="">Select</option>
                            {rm === null ? <option>Loading</option> : rm.map((data)=>(
                            <option key={data.id} value={data.id}>{data.name}</option>
                          ))}
                        </Field>
                        <ErrorField><ErrorMessage name="rm"/></ErrorField>
                      </FormGroup></p>
                        </div>
                        <div className="col-md-4">
                          <p> 
                           <FormGroup label="Key Handover">
                           <Field  className='form-control' type="text" name="keyHandover" placeholder="Key Hand Over - " />
                        <ErrorField><ErrorMessage name="rm"/></ErrorField>
                           </FormGroup>
                        </p>
                        </div>
                      </div>
                      <h4>User Details</h4>
                      <div className="row my-4">
                        <div className="col-md-6">
                          <h6 
                            style={{
                            background: "#ededed",
                            padding: "5px",
                            width: "max-content",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#03396c",
                        }}>
                          Username
                        </h6>
                          <p>{journey?.user?.name}</p>
                        </div>
                        <div className="col-md-6">
                          <h6 
                            style={{
                              background: "#ededed",
                              padding: "5px",
                              width: "max-content",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#03396c",
                          }}>
                            Mobile Number
                          </h6>
                          <p>{journey?.user?.mobile}</p>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-6">
                        <h6 
                          style={{
                            background: "#ededed",
                            padding: "5px",
                            width: "max-content"  ,
                            fontSize: "14px", 
                            fontWeight: "bold",
                            color: "#03396c",
                          }}>
                            Email
                          </h6>
                          <p>{journey?.user?.email}</p>
                        </div>

                        <div className="col-md-6">
                        <h6 
                          style={{
                            background: "#ededed",
                            padding: "5px",
                            width: "max-content"  ,
                            fontSize: "14px", 
                            fontWeight: "bold",
                            color: "#03396c",
                          }}>
                            User Type
                          </h6>
                          <p>{journey?.user?.type}</p>
                        </div>
                      </div>
                      <br></br>
                      <h4>Property Details</h4>
                      <div className="row my-4">
                        <div className="col-md-6">
                          <h6 
                            style={{
                            background: "#ededed",
                            padding: "5px",
                            width: "max-content",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#03396c",
                        }}>
                          Society Name
                        </h6>
                          <p>{journey?.societyName}</p>
                        </div>
                        <div className="col-md-6">
                          <h6 
                            style={{
                              background: "#ededed",
                              padding: "5px",
                              width: "max-content",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#03396c",
                          }}>
                            Saleable Area
                          </h6>
                         <span className="d-flex">
                          <p className="mr-5"><strong>Area</strong>: {journey?.sellableArea?.area}</p>
                          <p><strong>Average</strong>: {journey?.sellableArea?.average}</p>
                          </span>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-6">
                        <h6 
                          style={{
                            background: "#ededed",
                            padding: "5px",
                            width: "max-content"  ,
                            fontSize: "14px", 
                            fontWeight: "bold",
                            color: "#03396c",
                          }}>
                            Furnishing
                          </h6>
                          <p>{journey?.furnishing}</p>
                        </div>
                        </div>
                        <br></br>
                      <h4>Property Images</h4>
                      <FormGroup label="Property Images" required>
                        <div className="custom-file-upload-wrapper" style={{display:"block"}}>
                          <span>Choose File</span>
                          <label
                            htmlFor="aadharInput"
                            className="custom-file-upload"
                          >
                            {(updatedJourney && updatedJourney.images && updatedJourney.images.length) ? updatedJourney.images[0] : "No file Uploaded"}
                          </label>
                          <div className="">
                            {updatedJourney && updatedJourney.images && (
                              <>
                                {updatedJourney.images.map((item,idx)=>( 
                                <a
                                  key={idx}
                                    href={item}
                                    target="_blank"
                                  >
                                    <img src={item} width={150} height={100} style={{objectFit:"cover", margin:"1rem 1rem 1rem 0"}} />
                                  </a>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                        <input
                          id="aadharInput"
                          type="file"
                          name="token_documentation_aadhar"
                          ref={aadharRef}
                          onChange={(e) => handleImageUpload(e, "AadharImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_documentation_aadhar" />
                        </ErrorField>
                      </FormGroup>
                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          // className={`btn ${
                          //   userDetailsStatus
                          //     ? "btn-secondary"
                          //     : "btn-primary"
                          // }`}
                          className={`btn btn-primary`}
                          // disabled={userDetailsStatus}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>

                    {/* TAB 1 -->> (House Inspection) */}
                    <div className="tab-pane" id="tab-house-inspection">
                      <h4>House Inspection</h4>
                      <FormGroup label="Date" required>
                        <Field
                          placeholder="Date"
                          name="house_inspection_date"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="house_inspection_date" />
                        </ErrorField>
                      </FormGroup>
                      {/* <FormGroup label="Date of Token" required>
                        <Field
                          type="date"
                          name="dateOfToken"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="dateOfToken" />
                        </ErrorField>
                      </FormGroup> */}
                      <FormGroup label="Time" required>
                        <Field
                          placeholder="Time"
                          name="house_inspection_time"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="house_inspection_time" />
                        </ErrorField>
                      </FormGroup>

                      {/* Inspection Verdict */}
                      <h4>Average Score</h4>
                      <FormGroup label="Property Age" required>
                        <Field
                          placeholder="Property Age"
                          name="inspection_verdict_propertyAge"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="inspection_verdict_propertyAge" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Apartment Condition" required>
                        <Field
                          placeholder="4"
                          name="inspection_verdict_apartment_condition"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="inspection_verdict_propertyAge" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Green Area" required>
                        <Field
                          placeholder="Green Area"
                          name="inspection_verdict_greenArea"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="inspection_verdict_greenArea" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Construction" required>
                        <Field
                          placeholder="Construction"
                          name="inspection_verdict_construction"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="inspection_verdict_construction" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Amenities" required>
                        <Field
                          placeholder="Amenities"
                          name="inspection_verdict_amenities"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="inspection_verdict_amenities" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Connectivity" required>
                        <Field
                          placeholder="Connectivity"
                          name="inspection_verdict_connectivity"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="inspection_verdict_connectivity" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Management" required>
                        <Field
                          placeholder="Management"
                          name="inspection_verdict_management"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="inspection_verdict_management" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Status" required>
                        <Field
                          as="select"
                          type="number"
                          name="inspection_verdict_status"
                          className="form-select"
                        >
                          <option value="pending">Pending</option>
                          <option value="inprogress">Inprogress</option>
                          <option value="completed">Completed</option>
                        </Field>
                        <ErrorField>
                          <ErrorMessage name="inspection_verdict_status" />
                        </ErrorField>
                      </FormGroup>

                      {/* Price */}
                      <h4>Price</h4>
                      <FormGroup label="Amount" required>
                        <Field
                          placeholder="Amount"
                          name="price"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="price" />
                        </ErrorField>
                      </FormGroup>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            houseInspectionStatus
                              ? "btn-secondary"
                              : "btn-primary"
                          }`}
                          disabled={houseInspectionStatus}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>

                    {/* TAB 2 -->> (Token Documentation) */}
                    <div className="tab-pane" id="tab-token-documentation">
                      {/* Token Bank Details */}
                      <h4>Token Bank Details</h4>
                      <FormGroup label="Email" required>
                        <Field
                          placeholder="Email"
                          name="token_bank_details_email"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_bank_details_email" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Account Holder Name" required>
                        <Field
                          placeholder="Account Holder Name"
                          name="token_bank_details_accountName"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_bank_details_accountName" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Account Number" required>
                        <Field
                          placeholder="Account Number"
                          name="token_bank_details_accountNumber"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_bank_details_accountNumber" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="IFSC Code" required>
                        <Field
                          placeholder="IFSC"
                          name="token_bank_details_accountifsc"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_bank_details_accountifsc" />
                        </ErrorField>
                      </FormGroup>

                      <h4>Token Documentation</h4>
                      <FormGroup label="Aadhar" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="aadharInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.aadharImageUrl
                              ? uploadDocuments.aadharImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation.aadhar
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation.aadhar
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.aadharImageUrl
                                    ? uploadDocuments.aadharImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation.aadhar
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation.aadhar
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.aadharImageUrl &&
                                    uploadDocuments.aadharImageUrl[0]) ||
                                    (updatedJourney.token_documentation
                                      .aadhar &&
                                      updatedJourney.token_documentation
                                        .aadhar[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.aadharImageUrl
                                        ? uploadDocuments.aadharImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .aadhar
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .aadhar
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  // <iframe
                                  //   src="https://dev-assets.houseeazy.com/dummyPDF-1708789590013.pdf"
                                  //   width="200"
                                  //   height="200"
                                  // />
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="aadharInput"
                          type="file"
                          name="token_documentation_aadhar"
                          ref={aadharRef}
                          onChange={(e) => handleImageUpload(e, "AadharImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_documentation_aadhar" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Pan" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="panInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.panImageUrl
                              ? uploadDocuments.panImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation.pan
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation.pan
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.panImageUrl
                                    ? uploadDocuments.panImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation.pan
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation.pan
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.panImageUrl &&
                                    uploadDocuments.panImageUrl[0]) ||
                                    (updatedJourney.token_documentation.pan &&
                                      updatedJourney.token_documentation.pan[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.panImageUrl
                                        ? uploadDocuments.panImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation.pan
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation.pan
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="panInput"
                          type="file"
                          name="token_documentation_pan"
                          ref={panRef}
                          onChange={(e) => handleImageUpload(e, "PanImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_documentation_pan" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Registry" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="registryInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.registryImageUrl
                              ? uploadDocuments.registryImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation.registry
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation.registry
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.registryImageUrl
                                    ? uploadDocuments.registryImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation
                                        .registry
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation
                                        .registry
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.registryImageUrl &&
                                    uploadDocuments.registryImageUrl[0]) ||
                                    (updatedJourney.token_documentation
                                      .registry &&
                                      updatedJourney.token_documentation
                                        .registry[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.registryImageUrl
                                        ? uploadDocuments.registryImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .registry
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .registry
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="registryInput"
                          type="file"
                          name="token_documentation_registry"
                          ref={registryRef}
                          onChange={(e) =>
                            handleImageUpload(e, "RegistryImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_documentation_pan" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Allotment Letter">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="allotmentInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.allotmentImageUrl[0]
                              ? uploadDocuments.allotmentImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation
                                  .allotment_letter
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation
                                  .allotment_letter
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.allotmentImageUrl
                                    ? uploadDocuments.allotmentImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation
                                        .allotment_letter
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation
                                        .allotment_letter
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.allotmentImageUrl &&
                                    uploadDocuments.allotmentImageUrl[0]) ||
                                    (updatedJourney.token_documentation
                                      .allotment_letter &&
                                      updatedJourney.token_documentation
                                        .allotment_letter[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.allotmentImageUrl
                                        ? uploadDocuments.allotmentImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .allotment_letter
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .allotment_letter
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="allotmentInput"
                          type="file"
                          name="token_documentation_allotment_letter"
                          ref={allotmentRef}
                          onChange={(e) =>
                            handleImageUpload(e, "AllotmentImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_documentation_allotment_letter" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Property Document" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="propertyInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.propertyDocImageUrl[0]
                              ? uploadDocuments.propertyDocImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation
                                  .property_document
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation
                                  .property_document
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {Object.keys(updatedJourney).length && (
                              <a
                                href={
                                  uploadDocuments.propertyDocImageUrl
                                    ? uploadDocuments.propertyDocImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation
                                        .property_document
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation
                                        .property_document
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.propertyDocImageUrl &&
                                    uploadDocuments.propertyDocImageUrl[0]) ||
                                    (updatedJourney.token_documentation
                                      .property_document &&
                                      updatedJourney.token_documentation
                                        .property_document[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.propertyDocImageUrl
                                        ? uploadDocuments.propertyDocImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .property_document
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .property_document
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="propertyInput"
                          type="file"
                          name="token_documentation_property_document"
                          ref={propertyRef}
                          onChange={(e) =>
                            handleImageUpload(e, "PropertyDocImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_documentation_property_document" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Loan" required>
                        <Field
                          placeholder="Loan"
                          name="token_documentation_isLoan"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_documentation_isLoan" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Loan Document">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="loandocInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.loanDocImageUrl
                              ? uploadDocuments.loanDocImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation.loan_document
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.token_documentation.loan_document
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.loanDocImageUrl
                                    ? uploadDocuments.loanDocImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation
                                        .loan_document
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.token_documentation
                                        .loan_document
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.loanDocImageUrl &&
                                    uploadDocuments.loanDocImageUrl[0]) ||
                                    (updatedJourney.token_documentation
                                      .loan_document &&
                                      updatedJourney.token_documentation
                                        .loan_document[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.loanDocImageUrl
                                        ? uploadDocuments.loanDocImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .loan_document
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation
                                            .loan_document
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="loandocInput"
                          type="file"
                          name="token_documentation_loan_document"
                          ref={loandocRef}
                          onChange={(e) => handleImageUpload(e, "LoanDocImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_documentation_loan_document" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Loan Amount">
                        <Field
                          placeholder="Loan Amount"
                          name="token_documentation_loan_amount"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_documentation_loan_amount" />
                        </ErrorField>
                      </FormGroup>

                      {/* Token Money */}
                      <h4>Token Money</h4>
                      <FormGroup label="Token Amount" required>
                        <Field
                          placeholder="Amount"
                          name="token_money_amount"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_money_amount" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Token Money" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="tokenInvoiceInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.invoiceImageUrl
                              ? uploadDocuments.invoiceImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.token_money.invoice
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.token_money.invoice
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.invoiceImageUrl
                                    ? uploadDocuments.invoiceImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.token_money.invoice
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.token_money.invoice
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  uploadDocuments.invoiceImageUrl[0] ||
                                    (updatedJourney.token_money.invoice
                                      ? updatedJourney.token_money.invoice
                                      : "")
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.invoiceImageUrl
                                        ? uploadDocuments.invoiceImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.token_money.invoice
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.token_money.invoice
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="tokenInvoiceInput"
                          type="file"
                          name="token_money_invoice"
                          ref={invoiceRef}
                          onChange={(e) => handleImageUpload(e, "TokenInvoice")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="token_money_invoice" />
                        </ErrorField>
                      </FormGroup>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            tokenDocumentationStatus
                              ? "btn-secondary"
                              : "btn-primary"
                          }`}
                          disabled={tokenDocumentationStatus}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>

                    {/* TAB 3 -->> (AMA) */}
                    <div className="tab-pane" id="tab-token-ama">
                      <h4>AMA</h4>
                      <FormGroup label="Document Link" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="amaInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.amaDocImageUrl
                              ? uploadDocuments.amaDocImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.ama.doc_link
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.ama.doc_link
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.amaDocImageUrl
                                    ? uploadDocuments.amaDocImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.ama.doc_link
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.ama.doc_link
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  uploadDocuments.amaDocImageUrl[0] ||
                                    (updatedJourney.ama.doc_link
                                      ? updatedJourney.ama.doc_link
                                      : "")
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.amaDocImageUrl
                                        ? uploadDocuments.amaDocImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.ama.doc_link
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.ama.doc_link
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="amaInput"
                          type="file"
                          name="ama_doc_link"
                          ref={amaRef}
                          onChange={(e) => handleImageUpload(e, "AMAImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="ama_doc_link" />
                        </ErrorField>
                      </FormGroup>

                      {/* Advance Earnest Money */}
                      <h4>Advance Earnest Money</h4>
                      <FormGroup label="Final Price" required>
                        <Field
                          placeholder="Final Price"
                          name="advance_earnest_money_final_price"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_earnest_money_final_price" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Token Amount" required>
                        <Field
                          placeholder="Token Amount"
                          name="advance_earnest_money_token_amount"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_earnest_money_token_amount" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Balance Advance" required>
                        <Field
                          placeholder="Balance Advance"
                          name="advance_earnest_money_balance_advance"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_earnest_money_balance_advance" />
                        </ErrorField>
                      </FormGroup>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            amaStatus ? "btn-secondary" : "btn-primary"
                          }`}
                          disabled={amaStatus}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>

                    {/* TAB 4 -->> (Property Documents) */}
                    <div className="tab-pane" id="tab-property-documents">
                      <h4>Property Documentation</h4>

                      <FormGroup label="Builder Buyer Agreement">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="builderBuyerInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.BuilderBuyerImageUrl
                              ? uploadDocuments.BuilderBuyerImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.builderBuyerAgreement
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.builderBuyerAgreement
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.BuilderBuyerImageUrl
                                    ? uploadDocuments.BuilderBuyerImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.builderBuyerAgreement
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.builderBuyerAgreement
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.BuilderBuyerImageUrl &&
                                    uploadDocuments.BuilderBuyerImageUrl[0]) ||
                                    (updatedJourney.property_document
                                      .builderBuyerAgreement &&
                                      updatedJourney.property_document
                                        .builderBuyerAgreement[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.BuilderBuyerImageUrl
                                        ? uploadDocuments.BuilderBuyerImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document
                                            .builderBuyerAgreement
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document
                                            .builderBuyerAgreement
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="builderBuyerInput"
                          type="file"
                          name="property_document_builderBuyerAgreement"
                          ref={builderBuyerRef}
                          onChange={(e) => handleImageUpload(e, "BuilderBuyerImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_builderBuyerAgreement" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Builder No Dues">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="builderNoInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.builderNoDuesImageUrl
                              ? uploadDocuments.builderNoDuesImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.builderNoDues
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.builderNoDues
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.builderNoDuesImageUrl
                                    ? uploadDocuments.builderNoDuesImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.builderNoDues
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.builderNoDues
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.builderNoDuesImageUrl &&
                                    uploadDocuments.builderNoDuesImageUrl[0]) ||
                                    (updatedJourney.property_document.builderNoDues &&
                                      updatedJourney.property_document.builderNoDues[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.builderNoDuesImageUrl
                                        ? uploadDocuments.builderNoDuesImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.builderNoDues
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.builderNoDues
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="builderNoInput"
                          type="file"
                          name="property_document_builderNoDues"
                          ref={builderNoDueRef}
                          onChange={(e) => handleImageUpload(e, "BuilderNoDuesImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_builderNoDues" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Car Parking Letter">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="carParkingInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.carParkingLetterImageUrl
                              ? uploadDocuments.carParkingLetterImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.carParkingLetter
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.carParkingLetter
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.carParkingLetterImageUrl
                                    ? uploadDocuments.carParkingLetterImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.carParkingLetter
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.carParkingLetter
                                    : ""}
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.carParkingLetterImageUrl &&
                                    uploadDocuments.carParkingLetterImageUrl[0]) ||
                                    (updatedJourney.property_document.carParkingLetter &&
                                      updatedJourney.property_document.carParkingLetter[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.carParkingLetterImageUrl
                                        ? uploadDocuments.carParkingLetterImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.carParkingLetter
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.carParkingLetter
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="carParkingInput"
                          type="file"
                          name="property_document_carParkingLetter"
                          ref={carParkingRef}
                          onChange={(e) =>
                            handleImageUpload(e, "CarParkingLetterImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_carParkingLetter" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="General Power Of Attorney">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="generalInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.generalPowerOfAttorneyImageUrl[0]
                              ? uploadDocuments.generalPowerOfAttorneyImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.generalPowerOfAttorney
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.generalPowerOfAttorney
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {updatedJourney && (
                              <a
                                href={
                                  uploadDocuments.generalPowerOfAttorneyImageUrl
                                    ? uploadDocuments.generalPowerOfAttorneyImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.generalPowerOfAttorney
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.generalPowerOfAttorney
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.generalPowerOfAttorneyImageUrl &&
                                    uploadDocuments.generalPowerOfAttorneyImageUrl[0]) ||
                                    (updatedJourney.property_document.generalPowerOfAttorney &&
                                      updatedJourney.property_document.generalPowerOfAttorney[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.generalPowerOfAttorneyImageUrl
                                        ? uploadDocuments.generalPowerOfAttorneyImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.generalPowerOfAttorney
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.token_documentation.generalPowerOfAttorney
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="generalInput"
                          type="file"
                          name="property_document_generalPowerOfAttorney"
                          ref={generalPowerRef}
                          onChange={(e) =>
                            handleImageUpload(e, "GeneralPowerOfAttorneyImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_generalPowerOfAttorney" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Loan For eClosure Certificate">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="eClosureInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.loanForeClosureCertificateImageUrl[0]
                              ? uploadDocuments.loanForeClosureCertificateImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.loanForeClosureCertificate
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.loanForeClosureCertificate
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {Object.keys(updatedJourney).length && (
                              <a
                                href={
                                  uploadDocuments.loanForeClosureCertificateImageUrl
                                    ? uploadDocuments.loanForeClosureCertificateImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.loanForeClosureCertificate
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.loanForeClosureCertificate
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.loanForeClosureCertificateImageUrl &&
                                    uploadDocuments.loanForeClosureCertificateImageUrl[0]) ||
                                    (updatedJourney.property_document.loanForeClosureCertificate &&
                                      updatedJourney.property_document.loanForeClosureCertificate[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.loanForeClosureCertificateImageUrl
                                        ? uploadDocuments.loanForeClosureCertificateImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.loanForeClosureCertificate
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.loanForeClosureCertificate
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="eClosureInput"
                          type="file"
                          name="property_document_loanForeClosureCertificate"
                          ref={loanforeClosureRef}
                          onChange={(e) =>
                            handleImageUpload(e, "LoanForeClosureCertificateImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_loanForeClosureCertificate" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Loan Sanction Letter">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="loanSanctionInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.loanSanctionLetterImageUrl[0]
                              ? uploadDocuments.loanSanctionLetterImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.loanSanctionLetter
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.loanSanctionLetter
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {Object.keys(updatedJourney).length && (
                              <a
                                href={
                                  uploadDocuments.loanSanctionLetterImageUrl
                                    ? uploadDocuments.loanSanctionLetterImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.loanSanctionLetter
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.loanSanctionLetter
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.loanSanctionLetterImageUrl &&
                                    uploadDocuments.loanSanctionLetterImageUrl[0]) ||
                                    (updatedJourney.property_document.loanSanctionLetter &&
                                      updatedJourney.property_document.loanSanctionLetter[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.loanSanctionLetterImageUrl
                                        ? uploadDocuments.loanSanctionLetterImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.loanSanctionLetter
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.loanSanctionLetter
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="loanSanctionInput"
                          type="file"
                          name="property_document_loanSanctionLetter"
                          ref={loanSanctionRef}
                          onChange={(e) =>
                            handleImageUpload(e, "LoanSanctionLetterImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_loanSanctionLetter" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Offer Of Possession">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="offerPossessionInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.offerOfPossessionImageUrl[0]
                              ? uploadDocuments.offerOfPossessionImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.offerOfPossession
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.offerOfPossession
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {Object.keys(updatedJourney).length && (
                              <a
                                href={
                                  uploadDocuments.offerOfPossessionImageUrl
                                    ? uploadDocuments.offerOfPossessionImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.offerOfPossession
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.offerOfPossession
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.offerOfPossessionImageUrl &&
                                    uploadDocuments.offerOfPossessionImageUrl[0]) ||
                                    (updatedJourney.property_document.offerOfPossession &&
                                      updatedJourney.property_document.offerOfPossession[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.offerOfPossessionImageUrl
                                        ? uploadDocuments.offerOfPossessionImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.offerOfPossession
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.offerOfPossession
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="offerPossessionInput"
                          type="file"
                          name="property_document_offerOfPossession"
                          ref={offerPossessionRef}
                          onChange={(e) =>
                            handleImageUpload(e, "OfferOfPossessionImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_offerOfPossession" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Permission To Mortgage">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="permissionMortgageInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.permissionToMortgageImageUrl[0]
                              ? uploadDocuments.permissionToMortgageImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.permissionToMortgage
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.permissionToMortgage
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {Object.keys(updatedJourney).length && (
                              <a
                                href={
                                  uploadDocuments.permissionToMortgageImageUrl
                                    ? uploadDocuments.permissionToMortgageImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.permissionToMortgage
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.permissionToMortgage
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.permissionToMortgageImageUrl &&
                                    uploadDocuments.permissionToMortgageImageUrl[0]) ||
                                    (updatedJourney.property_document.permissionToMortgage &&
                                      updatedJourney.property_document.permissionToMortgage[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.permissionToMortgageImageUrl
                                        ? uploadDocuments.permissionToMortgageImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.permissionToMortgage
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.permissionToMortgage
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="permissionMortgageInput"
                          type="file"
                          name="property_document_permissionToMortgage"
                          ref={permissionMortgageRef}
                          onChange={(e) =>
                            handleImageUpload(e, "PermissionToMortgageImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_permissionToMortgage" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Previous Registry">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="previousRegistryInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.previousRegistryImageUrl[0]
                              ? uploadDocuments.previousRegistryImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.previousRegistry
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.previousRegistry
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {Object.keys(updatedJourney).length && (
                              <a
                                href={
                                  uploadDocuments.previousRegistryImageUrl
                                    ? uploadDocuments.previousRegistryImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.previousRegistry
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.previousRegistry
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.previousRegistryImageUrl &&
                                    uploadDocuments.previousRegistryImageUrl[0]) ||
                                    (updatedJourney.property_document.previousRegistry &&
                                      updatedJourney.property_document.previousRegistry[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.previousRegistryImageUrl
                                        ? uploadDocuments.previousRegistryImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.previousRegistry
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.previousRegistry
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="previousRegistryInput"
                          type="file"
                          name="property_document_previousRegistry"
                          ref={previousRegistryRef}
                          onChange={(e) =>
                            handleImageUpload(e, "previousRegistryRef")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_previousRegistry" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Provisional Allotment Letter">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="provisionalAllotmentInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.provisionalAllotmentLetterImageUrl[0]
                              ? uploadDocuments.provisionalAllotmentLetterImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.provisionalAllotmentLetter
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.provisionalAllotmentLetter
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {Object.keys(updatedJourney).length && (
                              <a
                                href={
                                  uploadDocuments.provisionalAllotmentLetterImageUrl
                                    ? uploadDocuments.provisionalAllotmentLetterImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.provisionalAllotmentLetter
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.provisionalAllotmentLetter
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.provisionalAllotmentLetterImageUrl &&
                                    uploadDocuments.provisionalAllotmentLetterImageUrl[0]) ||
                                    (updatedJourney.property_document.provisionalAllotmentLetter &&
                                      updatedJourney.property_document.provisionalAllotmentLetter[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.provisionalAllotmentLetterImageUrl
                                        ? uploadDocuments.provisionalAllotmentLetterImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.provisionalAllotmentLetter
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.provisionalAllotmentLetter
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="provisionalAllotmentInput"
                          type="file"
                          name="property_document_provisionalAllotmentLetter"
                          ref={provisionalAllotmentRef}
                          onChange={(e) =>
                            handleImageUpload(e, "ProvisionalAllotmentLetterImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_provisionalAllotmentLetter" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Transfer Memorandum">
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="transferMemoInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.transferMemorandumImageUrl[0]
                              ? uploadDocuments.transferMemorandumImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.transferMemorandum
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.property_document.transferMemorandum
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {Object.keys(updatedJourney).length && (
                              <a
                                href={
                                  uploadDocuments.transferMemorandumImageUrl
                                    ? uploadDocuments.transferMemorandumImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.transferMemorandum
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.property_document.transferMemorandum
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  (uploadDocuments.transferMemorandumImageUrl &&
                                    uploadDocuments.transferMemorandumImageUrl[0]) ||
                                    (updatedJourney.property_document.transferMemorandum &&
                                      updatedJourney.property_document.transferMemorandum[0])
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.transferMemorandumImageUrl
                                        ? uploadDocuments.transferMemorandumImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.transferMemorandum
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.property_document.transferMemorandum
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="transferMemoInput"
                          type="file"
                          name="property_document_transferMemorandum"
                          ref={transferMemorandumRef}
                          onChange={(e) =>
                            handleImageUpload(e, "TransferMemorandumImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="property_document_transferMemorandum" />
                        </ErrorField>
                      </FormGroup>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            propertyDocumentsStatus
                              ? "btn-secondary"
                              : "btn-primary"
                          }`}
                          disabled={propertyDocumentsStatus}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>

                    {/* TAB 5 -->> (Advance Bank Details) */}
                    <div className="tab-pane" id="tab-advance-bank-details">
                      <h4>Advance Bank Details</h4>
                      <FormGroup label="Email" required>
                        <Field
                          placeholder="Email"
                          name="advance_bank_details_email"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_email" />
                        </ErrorField>
                      </FormGroup>
                      {/* Advance Bank Details + Final Documetation */}
                      <h5
                        style={{
                          background: "#ededed",
                          padding: "5px",
                          width: "max-content",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#03396c",
                        }}
                      >
                        Primary Bank Account Details
                      </h5>
                      <FormGroup label="Account Name">
                        <Field
                          placeholder="Account Name"
                          name="advance_bank_details_primaryAccountName"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_primaryAccountName" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Account Number">
                        <Field
                          placeholder="Account Number"
                          name="advance_bank_details_primaryAccountNumber"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_primaryAccountNumber" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="IFSC">
                        <Field
                          placeholder="IFSC"
                          name="advance_bank_details_primaryAccountIfsc"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_primaryAccountIfsc" />
                        </ErrorField>
                      </FormGroup>

                      <h5
                        style={{
                          background: "#ededed",
                          padding: "5px",
                          width: "max-content",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#03396c",
                        }}
                      >
                        Secondary Bank Account Details
                      </h5>
                      <FormGroup label="Account Name">
                        <Field
                          placeholder="Account Name"
                          name="advance_bank_details_secondaryAccountName"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_secondaryAccountName" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Account Number">
                        <Field
                          placeholder="Account Number"
                          name="advance_bank_details_secondaryAccountNumber"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_secondaryAccountNumber" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="IFSC">
                        <Field
                          placeholder="IFSC"
                          name="advance_bank_details_secondaryAccountIfsc"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_secondaryAccountIfsc" />
                        </ErrorField>
                      </FormGroup>

                      <h5
                        style={{
                          background: "#ededed",
                          padding: "5px",
                          width: "max-content",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#03396c",
                        }}
                      >
                        Tertiary Bank Account Details
                      </h5>
                      <FormGroup label="Account Name">
                        <Field
                          placeholder="Account Name"
                          name="advance_bank_details_tertiaryAccountName"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_tertiaryAccountName" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Account Number">
                        <Field
                          placeholder="Account Number"
                          name="advance_bank_details_tertiaryAccountNumber"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_tertiaryAccountNumber" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="IFSC">
                        <Field
                          placeholder="IFSC"
                          name="advance_bank_details_tertiaryAccountIfsc"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="advance_bank_details_tertiaryAccountIfsc" />
                        </ErrorField>
                      </FormGroup>

                      <h4>Final Documentation (Part-1)</h4>

                      <FormGroup label="Earnest Money Doc Link" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="earnestInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.earnestImageUrl
                              ? uploadDocuments.earnestImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation
                                  .earnest_money_doc_link
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation
                                  .earnest_money_doc_link
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            {Object.keys(updatedJourney).length && (
                              <a
                                href={
                                  uploadDocuments.earnestImageUrl
                                    ? uploadDocuments.earnestImageUrl
                                    : Object.keys(updatedJourney).length &&
                                      updatedJourney.final_documentation
                                        .earnest_money_doc_link
                                    ? Object.keys(updatedJourney).length &&
                                      updatedJourney.final_documentation
                                        .earnest_money_doc_link
                                    : ""
                                }
                                target="_blank"
                              >
                                {!isPDFUrl(
                                  uploadDocuments.earnestImageUrl[0] ||
                                    (updatedJourney.final_documentation
                                      .earnest_money_doc_link
                                      ? updatedJourney.final_documentation
                                          .earnest_money_doc_link
                                      : "")
                                ) ? (
                                  <img
                                    src={
                                      uploadDocuments.earnestImageUrl
                                        ? uploadDocuments.earnestImageUrl
                                        : Object.keys(updatedJourney).length &&
                                          updatedJourney.final_documentation
                                            .earnest_money_doc_link
                                        ? Object.keys(updatedJourney).length &&
                                          updatedJourney.final_documentation
                                            .earnest_money_doc_link
                                        : ""
                                    }
                                    width={200}
                                  />
                                ) : (
                                  <img
                                    src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                    width={40}
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        <input
                          id="earnestInput"
                          type="file"
                          name="earnest_money_doc_link"
                          ref={earnestRef}
                          onChange={(e) => handleImageUpload(e, "EarnestImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="earnest_money_doc_link" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Earnest Status" required>
                        <Field
                          as="select"
                          type="number"
                          name="earnest_status"
                          className="form-select"
                        >
                          <option value="pending">Pending</option>
                          <option value="inprogress">Inprogress</option>
                          <option value="completed">Completed</option>
                        </Field>
                        <ErrorField>
                          <ErrorMessage name="earnest_status" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="ATS Status" required>
                        <Field
                          as="select"
                          type="number"
                          name="ats_status"
                          className="form-select"
                        >
                          <option value="pending">Pending</option>
                          <option value="inprogress">Inprogress</option>
                          <option value="completed">Completed</option>
                        </Field>
                        <ErrorField>
                          <ErrorMessage name="ats_status" />
                        </ErrorField>
                      </FormGroup>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            advanceBankDetailsStatus
                              ? "btn-secondary"
                              : "btn-primary"
                          }`}
                          disabled={advanceBankDetailsStatus}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>

                    {/* TAB 6 -->> (ATS) */}

                    <div className="tab-pane" id="tab-ats">
                      <h4>ATS Draft</h4>
                      {/* refundStatus */}
                      <FormGroup label="ATS Draft" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="atsInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.atsImageUrl
                              ? uploadDocuments.atsImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.ats
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.ats
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            <a
                              href={
                                uploadDocuments.atsImageUrl
                                  ? uploadDocuments.atsImageUrl
                                  : Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.ats
                                  ? Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.ats
                                  : ""
                              }
                              target="_blank"
                            >
                              {!isPDFUrl(
                                uploadDocuments.atsImageUrl[0] ||
                                  (updatedJourney.final_documentation.ats
                                    ? updatedJourney.final_documentation.ats
                                    : "")
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.atsImageUrl
                                      ? uploadDocuments.atsImageUrl
                                      : Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.ats
                                      ? Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.ats
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                  width={40}
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <input
                          id="atsInput"
                          type="file"
                          name="ats_draft"
                          ref={atsRef}
                          onChange={(e) => handleImageUpload(e, "ATSImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="ats_draft" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="ATS Signed" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="ats_signed"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.atsSignedImageUrl
                              ? uploadDocuments.atsSignedImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.ats_signed
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.ats_signed
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            <a
                              href={
                                uploadDocuments.atsSignedImageUrl
                                  ? uploadDocuments.atsSignedImageUrl
                                  : Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.ats_signed
                                  ? Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.ats_signed
                                  : ""
                              }
                              target="_blank"
                            >
                              {!isPDFUrl(
                                uploadDocuments.atsSignedImageUrl[0] ||
                                  (updatedJourney.final_documentation.ats_signed
                                    ? updatedJourney.final_documentation.ats_signed
                                    : "")
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.atsSignedImageUrl
                                      ? uploadDocuments.atsSignedImageUrl
                                      : Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.ats_signed
                                      ? Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.ats_signed
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                  width={40}
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <input
                          id="ats_signed"
                          type="file"
                          name="ats_signed"
                          ref={atsRef}
                          onChange={(e) => handleImageUpload(e, "ats_signed")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="ats_signed" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="NOC Signed" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="noc_signed"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.nocSignedImageUrl
                              ? uploadDocuments.nocSignedImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.noc_signed
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.noc_signed
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            <a
                              href={
                                uploadDocuments.nocSignedImageUrl
                                  ? uploadDocuments.nocSignedImageUrl
                                  : Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.noc_signed
                                  ? Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.noc_signed
                                  : ""
                              }
                              target="_blank"
                            >
                              {!isPDFUrl(
                                uploadDocuments.nocSignedImageUrl[0] ||
                                  (updatedJourney.final_documentation.noc_signed
                                    ? updatedJourney.final_documentation.noc_signed
                                    : "")
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.nocSignedImageUrl
                                      ? uploadDocuments.nocSignedImageUrl
                                      : Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.noc_signed
                                      ? Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.noc_signed
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                  width={40}
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <input
                          id="noc_signed"
                          type="file"
                          name="noc_signed"
                          ref={atsRef}
                          onChange={(e) => handleImageUpload(e, "noc_signed")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="noc_signed" />
                        </ErrorField>
                      </FormGroup>
                      <FormGroup label="Loan Documents" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="tm_signed"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.tmSignedImageUrl
                              ? uploadDocuments.tmSignedImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.tm_signed
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.tm_signed
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            <a
                              href={
                                uploadDocuments.tmSignedImageUrl
                                  ? uploadDocuments.tmSignedImageUrl
                                  : Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.tm_signed
                                  ? Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.tm_signed
                                  : ""
                              }
                              target="_blank"
                            >
                              {!isPDFUrl(
                                uploadDocuments.tmSignedImageUrl[0] ||
                                  (updatedJourney.final_documentation.tm_signed
                                    ? updatedJourney.final_documentation.tm_signed
                                    : "")
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.tmSignedImageUrl
                                      ? uploadDocuments.tmSignedImageUrl
                                      : Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.tm_signed
                                      ? Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.tm_signed
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                  width={40}
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <input
                          id="tm_signed"
                          type="file"
                          name="tm_signed"
                          ref={atsRef}
                          onChange={(e) => handleImageUpload(e, "tm_signed")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="tm_signed" />
                        </ErrorField>
                      </FormGroup>
                      {/* <FormGroup label="Draft Status (ATS)" required>
                        <Field
                          placeholder="Draft Status (ATS)"
                          name="ats_draft_status"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="ats_draft_status" />
                        </ErrorField>
                      </FormGroup> */}

                      <FormGroup label="Earnest Money refund complete">
                          <Field as="select" name="ats_refund_status" className='form-select'>
                            <option value={0}>False</option>
                            <option value={1}>True</option>
                          </Field>
                          <ErrorField><ErrorMessage name="ats_refund_status"/></ErrorField>
                      </FormGroup>

                      {/* <FormGroup label="Refund Status (ATS)" required>
                        <Field
                          placeholder="Refund Status (ATS)"
                          name="ats_refund_status"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="ats_refund_status" />
                        </ErrorField>
                      </FormGroup> */}

                      <div style={{ display: "none" }}>
                        <FormGroup label="ATS Status" required>
                          <Field
                            as="select"
                            type="number"
                            name="ats_status"
                            className="form-select"
                          >
                            <option value="pending">Pending</option>
                            <option value="inprogress">Inprogress</option>
                            <option value="completed">Completed</option>
                          </Field>
                          <ErrorField>
                            <ErrorMessage name="ats_status" />
                          </ErrorField>
                        </FormGroup>
                      </div>
                      <div style={{ display: "none" }}>
                        <FormGroup label="NOC Status" required>
                          <Field
                            as="select"
                            type="number"
                            name="noc_status"
                            className="form-select"
                          >
                            <option value="pending">Pending</option>
                            <option value="inprogress">Inprogress</option>
                            <option value="completed">Completed</option>
                          </Field>
                          <ErrorField>
                            <ErrorMessage name="noc_status" />
                          </ErrorField>
                        </FormGroup>
                      </div>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          // className="btn btn-primary"
                          className={`btn ${
                            atsStatus ? "btn-secondary" : "btn-primary"
                          }`}
                          disabled={atsStatus}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>

                    {/* TAB 7 -->> (NOC) */}
                    {/* <div className="tab-pane" id="tab-final-documentation-2">
                      <h4>Final Documentation (NOC)</h4>
                      <FormGroup label="NOC Status" required>
                        <Field
                          as="select"
                          type="number"
                          name="noc_status"
                          className="form-select"
                        >
                          <option value="pending">Pending</option>
                          <option value="inprogress">Inprogress</option>
                          <option value="completed">Completed</option>
                        </Field>
                        <ErrorField>
                          <ErrorMessage name="noc_status" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="NOC" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="nocInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.nocImageUrl
                              ? uploadDocuments.nocImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.noc
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.noc
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            <a
                              href={
                                uploadDocuments.nocImageUrl
                                  ? uploadDocuments.nocImageUrl
                                  : Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.noc
                                  ? Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.noc
                                  : ""
                              }
                              target="_blank"
                            >
                              {!isPDFUrl(
                                uploadDocuments.nocImageUrl[0] ||
                                  (updatedJourney.final_documentation.noc
                                    ? updatedJourney.final_documentation.noc[0]
                                    : "")
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.nocImageUrl
                                      ? uploadDocuments.nocImageUrl
                                      : Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.noc
                                      ? Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.noc
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                  width={40}
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <input
                          id="nocInput"
                          type="file"
                          name="noc"
                          ref={nocRef}
                          onChange={(e) => handleImageUpload(e, "NOCImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="noc" />
                        </ErrorField>
                      </FormGroup>

                      <div style={{ display: "none" }}>
                        <FormGroup label="TM Status" required>
                          <Field
                            as="select"
                            type="number"
                            name="tm_status"
                            className="form-select"
                          >
                            <option value="pending">Pending</option>
                            <option value="inprogress">Inprogress</option>
                            <option value="completed">Completed</option>
                          </Field>
                          <ErrorField>
                            <ErrorMessage name="tm_status" />
                          </ErrorField>
                        </FormGroup>
                      </div>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            finalDoc1Status ? "btn-secondary" : "btn-primary"
                          }`}
                          disabled={finalDoc1Status}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div> */}

                    {/* TAB 8 */}
                    {/* <div className="tab-pane" id="tab-final-documentation-3">
                      <h4>Final Documentation (TM)</h4>
                      <FormGroup label="TM Status" required>
                        <Field
                          as="select"
                          type="number"
                          name="tm_status"
                          className="form-select"
                        >
                          <option value="pending">Pending</option>
                          <option value="inprogress">Inprogress</option>
                          <option value="completed">Completed</option>
                        </Field>
                        <ErrorField>
                          <ErrorMessage name="tm_status" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="TM" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="tmInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.tmImageUrl
                              ? uploadDocuments.tmImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.tm
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.tm
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            <a
                              href={
                                uploadDocuments.tmImageUrl
                                  ? uploadDocuments.tmImageUrl
                                  : Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.tm
                                  ? Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.tm
                                  : ""
                              }
                            >
                              {!isPDFUrl(
                                uploadDocuments.tmImageUrl[0] ||
                                  (updatedJourney.final_documentation.tm
                                    ? updatedJourney.final_documentation.tm[0]
                                    : "")
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.tmImageUrl
                                      ? uploadDocuments.tmImageUrl
                                      : Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.tm
                                      ? Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.tm
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                  width={40}
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <input
                          id="tmInput"
                          type="file"
                          name="tm"
                          ref={tmRef}
                          onChange={(e) => handleImageUpload(e, "TMImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="tm" />
                        </ErrorField>
                      </FormGroup>

                      <div style={{ display: "none" }}>
                        <h4>AMF</h4>
                        <FormGroup label="AMF Status" required>
                          <Field
                            as="select"
                            type="number"
                            name="amf_status"
                            className="form-select"
                          >
                            <option value="pending">Pending</option>
                            <option value="inprogress">Inprogress</option>
                            <option value="completed">Completed</option>
                          </Field>
                          <ErrorField>
                            <ErrorMessage name="amf_status" />
                          </ErrorField>
                        </FormGroup>
                      </div>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            finalDoc2Status ? "btn-secondary" : "btn-primary"
                          }`}
                          disabled={finalDoc2Status}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div> */}

                    {/* TAB 9 */}
                    <div className="tab-pane" id="tab-final-documentation-4">
                      <h4>AMF</h4>
                      <div style={{ display: "none" }}>
                        <FormGroup label="AMF Status" required>
                          <Field
                            as="select"
                            type="number"
                            name="amf_status"
                            className="form-select"
                          >
                            <option value="pending">Pending</option>
                            <option value="inprogress">Inprogress</option>
                            <option value="completed">Completed</option>
                          </Field>
                          <ErrorField>
                            <ErrorMessage name="amf_status" />
                          </ErrorField>
                        </FormGroup>
                      </div>

                      <FormGroup label="AMF Fee Invoice" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="amfFeeInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.amfFeeImageUrl
                              ? uploadDocuments.amfFeeImageUrl
                              : updatedJourney.final_documentation &&
                                updatedJourney.final_documentation.amf &&
                                updatedJourney.final_documentation.amf
                                  .fee_invoice
                              ? updatedJourney.final_documentation.amf
                                  .fee_invoice
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            <a
                              href={
                                uploadDocuments.amfFeeImageUrl
                                  ? uploadDocuments.amfFeeImageUrl
                                  : Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.amf
                                  ? Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.amf
                                      .fee_invoice
                                  : ""
                              }
                              target="_blank"
                            >
                              {!isPDFUrl(
                                uploadDocuments.amfFeeImageUrl[0] ||
                                  (updatedJourney.final_documentation &&
                                  updatedJourney.final_documentation.amf &&
                                  updatedJourney.final_documentation.amf
                                    .fee_invoice
                                    ? updatedJourney.final_documentation.amf
                                        .fee_invoice
                                    : "")
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.amfFeeImageUrl
                                      ? uploadDocuments.amfFeeImageUrl
                                      : Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation &&
                                        updatedJourney.final_documentation
                                          .amf &&
                                        updatedJourney.final_documentation.amf
                                          .fee_invoice
                                      ? updatedJourney.final_documentation.amf
                                          .fee_invoice
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                  width={40}
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <input
                          id="amfFeeInput"
                          type="file"
                          name="amf_fee_invoice"
                          ref={amfFeeRef}
                          onChange={(e) => handleImageUpload(e, "AmfFeeImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="amf_fee" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="AMF Paid at Registry" required>
                        <Field
                          placeholder="AMF Paid at Registry"
                          name="amf_paid_at_registry"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="amf_paid_at_registry" />
                        </ErrorField>
                      </FormGroup>


                      <FormGroup label="AMF Proof of Payment" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="amfPPInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.amfPPImageUrl
                              ? uploadDocuments.amfPPImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.proofOfPayment
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation.proofOfPayment
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            <a
                              href={
                                uploadDocuments.amfPPImageUrl
                                  ? uploadDocuments.amfPPImageUrl
                                  : Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.proofOfPayment
                                  ? Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation.proofOfPayment
                                  : ""
                              }
                            >
                              {!isPDFUrl(
                                uploadDocuments.amfPPImageUrl[0] ||
                                  (updatedJourney.final_documentation.proofOfPayment
                                    ? updatedJourney.final_documentation.proofOfPayment[0]
                                    : "")
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.amfPPImageUrl
                                      ? uploadDocuments.amfPPImageUrl
                                      : Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.proofOfPayment
                                      ? Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation.proofOfPayment
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                  width={40}
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <input
                          id="amfPPInput"
                          type="file"
                          name="amf_proof_of_payment"
                          ref={amfPPRef}
                          onChange={(e) => handleImageUpload(e, "AMFPPImage")}
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="amf_proof_of_payment" />
                        </ErrorField>
                      </FormGroup>

                      <FormGroup label="Mode of Payment" required>
                        <Field
                          placeholder="Mode of Payment"
                          name="amf_mode_of_payment"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="amf_mode_of_payment" />
                        </ErrorField>
                      </FormGroup>

                      <h4>Final Documentation (Registry)</h4>
                      <FormGroup label="Registry Status" required>
                        <Field
                          as="select"
                          type="number"
                          name="registry_status"
                          className="form-select"
                        >
                          <option value="pending">Pending</option>
                          <option value="inprogress">Inprogress</option>
                          <option value="completed">Completed</option>
                        </Field>
                        <ErrorField>
                          <ErrorMessage name="registry_status" />
                        </ErrorField>
                      </FormGroup>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            finalDoc3Status ? "btn-secondary" : "btn-primary"
                          }`}
                          disabled={finalDoc3Status}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>

                    {/* TAB 10 */}
                    <div className="tab-pane" id="tab-final-documentation-5">
                      <h4>Final Registry</h4>
                      <FormGroup label="Registry Date" required>
                        <Field
                          placeholder="Registry Date"
                          name="registryDate"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="registryDate" />
                        </ErrorField>
                      </FormGroup>

                      {/* <FormGroup label="Registry Date" required>
                          <Field type="date" placeholder="Registry Date" name="registryDate" className='form-control' />
                          <ErrorField><ErrorMessage name="registryDate"/></ErrorField>
                      </FormGroup> */}

                      <FormGroup label="Registry Time" required>
                        <Field
                          placeholder="Registry Time"
                          name="registryTime"
                          className="form-control"
                        />
                        <ErrorField>
                          <ErrorMessage name="registryTime" />
                        </ErrorField>
                      </FormGroup>

                      {/* <FormGroup label="Registry Time" required>
                          <Field type="time" placeholder="Registry Time" name="registryTime" className='form-control' />
                          <ErrorField><ErrorMessage name="registryTime"/></ErrorField>
                      </FormGroup> */}

                      <FormGroup label="Registry" required>
                        <div className="custom-file-upload-wrapper">
                          <span>Choose File</span>
                          <label
                            htmlFor="registryFinalInput"
                            className="custom-file-upload"
                          >
                            {uploadDocuments.registryFinalImageUrl
                              ? uploadDocuments.registryFinalImageUrl
                              : Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation
                                  .registry_document
                              ? Object.keys(updatedJourney).length &&
                                updatedJourney.final_documentation
                                  .registry_document
                              : "No File Chosen"}
                          </label>
                          <div className="custom-file-preview">
                            <a
                              href={
                                uploadDocuments.registryFinalImageUrl
                                  ? uploadDocuments.registryFinalImageUrl
                                  : Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation
                                      .registry_document
                                  ? Object.keys(updatedJourney).length &&
                                    updatedJourney.final_documentation
                                      .registry_document
                                  : ""
                              }
                              target="_blank"
                            >
                              {!isPDFUrl(
                                uploadDocuments.registryFinalImageUrl[0] ||
                                  (updatedJourney.final_documentation
                                    .registry_document
                                    ? updatedJourney.final_documentation
                                        .registry_document
                                    : "")
                              ) ? (
                                <img
                                  src={
                                    uploadDocuments.registryFinalImageUrl
                                      ? uploadDocuments.registryFinalImageUrl
                                      : Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation
                                          .registry_document
                                      ? Object.keys(updatedJourney).length &&
                                        updatedJourney.final_documentation
                                          .registry_document
                                      : ""
                                  }
                                  width={200}
                                />
                              ) : (
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                  width={40}
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <input
                          id="registryFinalInput"
                          type="file"
                          name="registry_document"
                          ref={registryFinalRef}
                          onChange={(e) =>
                            handleImageUpload(e, "RegistryFinalImage")
                          }
                          style={{ display: "none" }}
                          accept=".jpg, .jpeg, .png, .webp, application/pdf"
                        />
                        <ErrorField>
                          <ErrorMessage name="registry_document" />
                        </ErrorField>
                      </FormGroup>
                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            finalDoc4Status ? "btn-secondary" : "btn-primary"
                          }`}
                          disabled={finalDoc4Status}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>

                    {/* TAB 11 */}
                    <div className="tab-pane" id="tab-journey-status">
                      <h4>Overall Journey Status</h4>
                      <div style={{ display: "none" }}>
                        <FormGroup label="Final Step Status" required>
                          <Field
                            as="select"
                            type="number"
                            name="final_documentation_status"
                            className="form-select"
                          >
                            <option value="pending">Pending</option>
                            <option value="inprogress">Inprogress</option>
                            <option value="completed">Completed</option>
                          </Field>
                          <ErrorField>
                            <ErrorMessage name="final_documentation_status" />
                          </ErrorField>
                        </FormGroup>
                      </div>

                      <FormGroup label="Journey Completion Status" required>
                        <Field
                          as="select"
                          type="number"
                          name="overall_journey_status"
                          className="form-select"
                        >
                          <option value="pending">Pending</option>
                          <option value="inprogress">Inprogress</option>
                          <option value="completed">Completed</option>
                        </Field>
                        <ErrorField>
                          <ErrorMessage name="overall_journey_status" />
                        </ErrorField>
                      </FormGroup>

                      <div className="my-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className={`btn ${
                            overAllJourneyStatus
                              ? "btn-secondary"
                              : "btn-primary"
                          }`}
                          disabled={overAllJourneyStatus}
                        >
                          Continue Journey
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-md-2 d-grid'>
                        <Button type="submit">Continue Journey</Button>
                    </div> */}
                </Form>
              )}
            </Formik>
          )}
        </DataView>
      </ContentLayout>
    </>
  );
}